export const POLYGON_CHAIN_ID = 137 // 137
export const POLYGON_CHAIN_ID_HEX = '0x89' // 137
export const POLYGON_RPC_URL = 'https://rpc-mainnet.maticvigil.com/v1/a5ba02a72ebb3e9794e7d00984d4b39a0f5c85ed'
export const POLYGONSCAN_KEY = 'D2X13TWY3D9KYC7TQ7C6X2RYD3ZN3379Q6'

export const BSC_CHAIN_ID = 56 // 137
export const BSC_CHAIN_ID_HEX = '0x38' // 137
export const BSC_RPC_URL = 'https://bsc-dataseed.binance.org'
export const BSCSCAN_KEY = 'TRD87KJKSHXEGV7WK8U3RREWJYRSSHMX9T'

export const BSCUSDT = '0x55d398326f99059fF775485246999027B3197955'
export const PolygonUSDT = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'

export const bsc22_5Asset = '0xed5efe8ae7C2C59edBfeE3775835fADF7A76d83b'
export const bsc22_5Control = '0xa90c8C911f436f8db642E66dD399916Ebb8BA686'

export const polygon22_5Asset = '0x54Ebf3E4612Ac4C2C7491393F922bad2F7c7e303'
export const polygon22_5Control = '0x0cC191737D0b7A7cc9925b78692520EE4d99e9BC'

export const Sepolia22_5Asset = '0xed5efe8ae7C2C59edBfeE3775835fADF7A76d83b'
export const Sepolia22_5Control = '0xa90c8C911f436f8db642E66dD399916Ebb8BA686'

export const polygontest22_5Asset = '0xcDBef08E438B5Fc8cCA69B1Fc515913d4f3FDF01'
export const polygontest22_5Control = '0x6618BDCe7c79Eb224276Ff300674188DB16f4638'

export const polygon3DAsset = '0xEc33FFA4cd1B8e681841BC3f7254321f59b76063'
export const polygon3DControl = '0xAA8A8fD96769C0508B1b708EAdC78e37eBE06EC7'

export const polygontest3DAsset = '0x52FF29E9fAdbc9e9C143A7276069ef3114383188'
export const polygontest3DControl = '0x19B9D79370208313b593fc849e95fdd13E19a8EE'

export const polygon5DAsset = ''
export const polygon5DControl = ''

export const polygontest5DAsset = '0x41F4218E923Ff1Ea259195B0dFF335ab7d9Bc862'
export const polygontest5DControl = '0x74de50534b426C6Aa60F31369778390A9A9eB203'