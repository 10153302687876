import { formatAddress } from '../utils'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
// import { ConnectButton } from '@rainbow-me/rainbowkit';
import { WrongNetwork } from './WrongNetwork'
import { ConnectWallet } from './ConnectWallet'
import { useActiveWeb3React, useStore } from '../hooks'

const AddressBox = styled.div`
  width: 50%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(90deg, #9741D3 0%, #0F69FF 100%);
  box-shadow: 6px 6px 20px 0px rgba(8,8,29,0.6), -4px -4px 10px 0px rgba(70,84,132,0.5);
  border-radius: 30px;
  margin-left: 20%;
`
export const AccountBar = () => {
  const context = useActiveWeb3React()
  const { account } = context
  const { networkError } = useStore()
  const history = useHistory()
  const UserPage = () => {
    history.push({
      pathname: `/dashboard`
    })
  }

  if (networkError) {
    return <WrongNetwork ghost danger />
  }

  if (account) {
    return <AddressBox className="flex flex-center" onClick={UserPage}>{formatAddress(account)}</AddressBox>
  }

  return <ConnectWallet />
  // return (
  //   <div className="flex flex-justify-content">
  //     <ConnectButton showBalance={false} accountStatus="address" />
  //   </div>
  // )
}
