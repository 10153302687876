import styled from 'styled-components';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import userAvatar from '../assets/User.png'
import basemap from '../assets/basemap.png'
import recttangle from '../assets/Rectangle2.png'
import icon_next from '../assets/icon_NextPage.png'
import icon_prev from '../assets/icon_PrevPage.png'
import loadding from '../assets/loading.svg'
import {
  useActiveWeb3React,
  useAsset22_5Contract,
  useAsset3DContract,
  useAsset5DContract,
  useControl22_5Contract,
  useControl3DContract,
  useControl5DContract,
  useUSDTContract
} from '../hooks';
import { AllBetsBox, PagingBar, WinnersListBox } from './Pool5_22';
import { lotteryhttp } from './Store';
import { ItemBall } from './ItemBall';
import { ItemWhiteBall } from './ItemWhiteBall';
import { TagBar } from './Pool3';


const MyPageBox = styled.div`
  background-image: url(${basemap});
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 50px;
  .color-4C83DF {
    color: #4C83DF;
  }
  .info {
    color: #fff;
    font-size: 20px;
    .address {
      margin-left: 30px;
    }
    .Balance {
      width: 300px;
      height: 40px;
      background: #131834;
      margin-top: 20px;
      box-shadow: -3px -2px 10px 0px rgba(0,0,0,0.6);
      border-radius: 20px;
      border: 1px solid #212A53;
    }
    .EditButton {
      width: 230px;
      height: 50px;
      background: linear-gradient(90deg, #9741D3 0%, #0F69FF 100%);
      box-shadow: 6px 6px 20px 0px rgba(8,8,29,0.6), -4px -4px 10px 0px rgba(70,84,132,0.5);
      border-radius: 30px;
      color: #fff;
      font-size: 24px;
      border: none;
    }
  }
  .barTitle {
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 20px;
    .border {
      width: 15px;
      height: 4px;
      background: #0F69FF;
      border-radius: 2px;
    }
    div {
      margin-left: 20px;
    }
  }
  .NotData {
    position: absolute;
    left: 48%;
  }
`
const StatisticsBox = styled.div`
  width: 100%;
  height: 380px;
  padding: 30px;
  background-image: url(${recttangle});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 20px;
  color: #fff;
  .title {
    color: #4D8BF5;
  }
  .value {
    margin-bottom: 15px;
  }
  .FavoriteGame {
    height: 50px;
    background: linear-gradient(90deg, #192045 0%, #263467 50%, #192045 100%);
    border-radius: 30px;
  }
`

export const MyPage = () => {
  const { account, library, chainId } = useActiveWeb3React()
  const USDTContract = useUSDTContract()
  const Control22_5 = useControl22_5Contract()
  const Asset22_5 = useAsset22_5Contract()
  const Control3D = useControl3DContract()
  const Asset3D = useAsset3DContract()
  const Asset5D = useAsset5DContract()
  const Control5D = useControl5DContract()
  const [ResultsTag, setResultsTag] = useState('Number')
  const chainName = chainId===56? 'bsc':chainId===137? 'polygon':chainId===80002?'amoy':'ethtest'
  const [wonList, setWonList] = useState([] as any)
  const [wonListAll, setWonListAll] = useState([] as any)
  const [lostList, setLostList] = useState([] as any)
  const [lostListAll, setLostListAll] = useState([] as any)
  const [betListAll, setBettListAll] = useState([] as any)
  const [stageListAll, setStageListAll] = useState([] as any)
  const [winnersListAll, setWinnersListAll] = useState([] as any)
  const [FavoriteGame, setFavoriteGame] = useState({} as any)
  const [refresh, setrefresh] = useState(false)
  const [listPage, setListPage] = useState(1)
  const [lostPage, setLostPage] = useState(1)
  const [Wagered, setWagered] = useState(0)
  const [USDTBalance, setUSDTBalance] = useState(0)
  const [ClaimLoadding, setClaimLoadding] = useState(-1)
 
  useEffect(() => {
    getUserBetList()
    getStageData()
    getUSDTBalance()
  },[chainId,refresh])

  useEffect(() => {
    setWonList(wonListAll.slice((listPage-1)*3, listPage*3))
  },[listPage])
  useEffect(() => {
    setLostList(lostListAll.slice((lostPage-1)*5, lostPage*5))
  },[lostPage])

  useEffect(() => {
    if (betListAll.length) {
      const type = ResultsTag==='Number'? 1: ResultsTag==='OddorEven'? 2 : 3
      const filterWinners = betListAll.filter((item: any) => {
        if (item.contracttype===1) {
          return item.winningStatus && item.bettype === type
        }
        if (!item.contracttype||item.contracttype===2&&ResultsTag==='Number') {
          return item.winningStatus >= 3
        }
      })
      const sortwon = filterWinners.sort((a: any,b: any)=> {return a.betNumbering*1-b.betNumbering*1})
      setWonList(sortwon.slice(0, 3))
      setWonListAll(sortwon)
      const filterallBets = betListAll.filter((item: any) => {
        if (item.contracttype===1) {
          return !item.winningStatus && item.bettype === type
        }
        if (!item.contracttype&&ResultsTag==='Number') {
          return item.winningStatus < 3
        }
        if (item.contracttype===2&&ResultsTag==='Number') {
          return item.winningStatus < 3
        }
      })
      const sortlost = filterallBets.sort((a: any,b: any)=> {return new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime()})
      setLostList(sortlost.slice(0, 5))
      setLostListAll(sortlost)
    }
  },[ResultsTag,betListAll])

  const getUserBetList = async () => {
    const betData = await lotteryhttp.get(`/v0/betInfo_22Choose5s?userAddress=${account}&chain=${chainName}`)
    const pl3Data = await lotteryhttp.get(`/v0/betInfo_pl3s?userAddress=${account}&chain=${chainName}`)
    const pl5Data = await lotteryhttp.get(`/v0/betInfo_pl5s?userAddress=${account}&chain=${chainName}`)
    const All = [...betData.data.data, ...pl3Data.data.data,...pl5Data.data.data]
    setBettListAll(All)
    const won = [] as any
    // const lost = [] as any
    let val = 0
    let choose22_5 = 0
    let pl3 = 0
    let pl5 = 0
    All.map((item: any) => {
      if (item.contracttype===1) {
        pl3 = pl3+1
        if (item.winningStatus) {
          won.push(item)
        }
      } else if (item.contracttype===2) {
        pl5 = pl5+1
        if (item.winningStatus>=3) {
          won.push(item)
        }
      } else {
        choose22_5 = choose22_5+1
        if (item.winningStatus>=3) {
          won.push(item)
        }
      }
      val = item.multiplier*2 + val
    })
    const QuantityStatistics = [
      {
        name: '3D Lottery',
        total: pl3
      },
      {
        name: '5/22 Lottery',
        total: choose22_5
      },
      {
        name: '5D Lottery',
        total: pl5
      }
    ]
    const sortArr = QuantityStatistics.sort((a: any, b:any) => {return b.total-a.total})
    setFavoriteGame(sortArr[0])
    setWagered(val)
    setWinnersListAll(won)
  }
  const getStageData = async () => {
    const stageListData = await lotteryhttp.get(`/v0/cj_Settlement_225s?chain=${chainName}`)
    const pl3stageList = await lotteryhttp.get(`/v0/cj_Settlement_pl3s?chain=${chainName}`)
    const pl5stageList = await lotteryhttp.get(`/v0/cj_Settlement_pl5s?chain=${chainName}`)
    setStageListAll([...stageListData.data.data,...pl3stageList.data.data,...pl5stageList.data.data])
  }

  const getUSDTBalance = async () => {
    const wei = chainId === 137||chainId === 80002? 1000000: 1000000000000000000
    const data = await USDTContract?.balanceOf(account)
    const balance = new BigNumber(data?.toString()).div(wei).toNumber().toFixed(2)
    setUSDTBalance(Number(balance)||0)
  }

  const pageButton = (type: string, dataname: string) => {
    if (dataname==='won') {
      if (type === 'next') {
        if (listPage>=Math.ceil(wonListAll.length/3)) return
        setListPage(listPage+1)
      }
      if (type === 'prev') {
        if (listPage<=1) return
        setListPage(listPage-1)
      }
    }
    if (dataname==='lost') {
      if (type === 'next') {
        if (lostPage>=Math.ceil(lostListAll.length/5)) return
        setLostPage(lostPage+1)
      }
      if (type === 'prev') {
        if (lostPage<=1) return
        setLostPage(lostPage-1)
      }
    }
  }

  const ClaimButton = async (item: any,index: number) => {
    if (ClaimLoadding>=0) return
    setClaimLoadding(index)
    if (item.contracttype === 1) {
      try {
        const deposited = await Control3D?.drawreward(item.drawNumbering,item.betNumbering)
        Asset3D?.on('drawreward', (from,value,betnumber,betstage)=>{
          // console.log(from,`${betnumber}`,`${betstage}`)
          if (
            from.toLowerCase() === account?.toLowerCase()
            &&`${betnumber}`===item.betNumbering
            &&`${betstage}`===item.drawNumbering
          ) {
            setClaimLoadding(-1)
            lotteryhttp.put(`/v0/betInfo_pl3s/${item.id}`,{rewardStatus: 1}).then(() => setrefresh(!refresh))
            Asset3D?.removeAllListeners('drawreward')
          }
        })
      } catch (error) {
        setClaimLoadding(-1)
        Asset3D?.removeAllListeners('drawreward')
      }
    }
    if (item.contracttype === 0) {
      try {
        const deposited = await Control22_5?.drawreward(item.drawNumbering,item.betNumbering)
        Asset22_5?.on('drawreward', (from,value,betnumber,betstage)=>{
          // console.log(from,`${betnumber}`,`${betstage}`)
          if (
            from.toLowerCase() === account?.toLowerCase()
            &&`${betnumber}`===item.betNumbering
            &&`${betstage}`===item.drawNumbering
          ) {
            setClaimLoadding(-1)
            lotteryhttp.put(`/v0/betInfo_22Choose5s/${item.id}`,{rewardStatus: 1}).then(() => setrefresh(!refresh))
            Asset22_5?.removeAllListeners('drawreward')
          }
        })
      } catch (error) {
        setClaimLoadding(-1)
        Asset22_5?.removeAllListeners('drawreward')
      }
    }
    if (item.contracttype === 2) {
      try {
        const deposited = await Control5D?.drawreward(item.drawNumbering,item.betNumbering)
        Asset5D?.on('drawreward', (from,value,betnumber,betstage)=>{
          // console.log(from,`${betnumber}`,`${betstage}`)
          if (
            from.toLowerCase() === account?.toLowerCase()
            &&`${betnumber}`===item.betNumbering
            &&`${betstage}`===item.drawNumbering
          ) {
            setClaimLoadding(-1)
            lotteryhttp.put(`/v0/betInfo_pl5s/${item.id}`,{rewardStatus: 1}).then(() => setrefresh(!refresh))
            Asset5D?.removeAllListeners('drawreward')
          }
        })
      } catch (error) {
        setClaimLoadding(-1)
        Asset5D?.removeAllListeners()
      }
    }
  }

  const getOverflowRate = (stage: string) => {
    const filterData = stageListAll.filter((item: any) => {
      return item.stage === Number(stage)
    })
    
    return filterData[0]?.overloadmultiples
  }

  const CalculateBonus = (item: any) => {
    if (!item.winningStatus) return 0
    if (getOverflowRate(item.drawNumbering) === '0') {
      if (item.contracttype===1) {
        if (item.winningStatus&&item.bettype===1) {
          return item.multiplier*500
        }
        if (item.winningStatus&&item.bettype===2) {
          console.log(item.multiplier)
          return item.multiplier*10
        }
        if (item.winningStatus&&item.bettype===3) {
          return item.multiplier*5
        }
      } else if (item.contracttype===2) {
        if (item.winningStatus === 3) {
          return item.multiplier*5
        }
        if (item.winningStatus === 4) {
          return item.multiplier*50
        }
        if (item.winningStatus === 5) {
          return item.multiplier*5000
        }
      } else {
        if (item.winningStatus === 3) {
          return item.multiplier*5
        }
        if (item.winningStatus === 4) {
          return item.multiplier*100
        }
        if (item.winningStatus === 5) {
          return item.multiplier*5000
        }
      }
    } else {
      const wei = chainId === 137||chainId === 80002? 1000000: 1000000000000000000
      const over = new BigNumber(getOverflowRate(item.drawNumbering)).div(wei).toNumber()
      if (item.contracttype===1) {
        if (item.winningStatus&&item.bettype===1) {
          const val = item.multiplier*5/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
        if (item.winningStatus&&item.bettype===2) {
          console.log(item.drawNumbering)
          const val = item.multiplier*100/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
        if (item.winningStatus&&item.bettype===3) {
          const val = item.multiplier*5000/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
      } else if (item.contracttype===2) {
        if (item.winningStatus === 3) {
          const val = item.multiplier*5/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
        if (item.winningStatus === 4) {
          const val = item.multiplier*50/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
        if (item.winningStatus === 5) {
          const val = item.multiplier*2000/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
      } else {
        if (item.winningStatus === 3) {
          const val = item.multiplier*5/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
        if (item.winningStatus === 4) {
          const val = item.multiplier*100/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
        if (item.winningStatus === 5) {
          const val = item.multiplier*5000/over
          return new BigNumber(val).times(0.95).toNumber().toFixed(2)
        }
      }
    }
  }

  const CalculateProfit = () => {
    if (!winnersListAll.length) {
      return {
        HighestWin: '$ 0',
        Multiplier: 0,
        Gross: '$ 0',
        Net: '$ 0'
      }
    }
    let Multiplier = 0
    let Gross = 0
    let Net = 0
    const WinArr = [] as any
    winnersListAll.map((item: any) =>{
      if (item.multiplier*1 > Multiplier) {
        Multiplier = item.multiplier*1
      }
      // console.log(CalculateBonus(item))
      Gross = Number(CalculateBonus(item)) + Gross
      WinArr.push(CalculateBonus(item))
    })
    // console.log('Gross:',Gross)
    const sortArr = WinArr.sort((a: any,b: any)=> {return b-a})
    return {
      HighestWin: `$ ${sortArr[0]}`,
      Multiplier: Multiplier,
      Gross: `$ ${Gross.toFixed(2)}`,
      Net: Gross-Wagered<0? `- $ ${Math.abs(Gross-Wagered).toFixed(2)}` : `$ ${(Gross-Wagered).toFixed(2)}`
    }
  }


  return (
    <MyPageBox className="main">
      <div className="info flex flex-v-center flex-h-between">
        <div className="flex flex-v-center">
            <img src={userAvatar} alt="" />
            <div className="address">
              <div className="color-4C83DF">{account}</div>
              <div className="Balance flex flex-center">Balance ：{USDTBalance} U</div>
            </div>
        </div>
      </div>
      <div className="barTitle flex flex-center">
        <div className="border"></div>
        <div>Statistics</div>
        <div className="border"></div>
      </div>
      <StatisticsBox className="borderbox">
        <div className="flex flex-h-between title">
          <div>Wagered</div>
          <div>Bets</div>
        </div>
        <div className="flex flex-h-between value">
          <div>$ {Wagered}</div>
          <div>{betListAll.length}</div>
        </div>
        <div className="flex flex-h-between title">
          <div>Bets Won</div>
          <div>Bets Lost</div>
        </div>
        <div className="flex flex-h-between value">
          <div>{wonList.length}</div>
          <div>{lostList.length}</div>
        </div>
        <div className="flex flex-h-between title">
          <div>Highest Win</div>
          <div>Highest Multiplier</div>
        </div>
        <div className="flex flex-h-between value">
          <div>{CalculateProfit()?.HighestWin}</div>
          <div>{CalculateProfit()?.Multiplier}</div>
        </div>
        <div className="FavoriteGame flex flex-h-between flex-v-center value">
          <div className="title">Favorite Game</div>
          <div>{betListAll.length?FavoriteGame?.name:''}</div>
        </div>
        <div className="flex flex-h-between title">
          <div >Gross Profit</div>
          <div>Net Profit</div>
        </div>
        <div className="flex flex-h-between value">
          <div>{CalculateProfit()?.Gross}</div>
          <div>{CalculateProfit()?.Net}</div>
        </div>
      </StatisticsBox>
      <div className="flex flex-justify-content">
        <TagBar className="flex">
          <div className={`left text-center ${ResultsTag === 'Number' ? 'current':''}`} onClick={() => setResultsTag('Number')}>Number</div>
          <div className={`item text-center ${ResultsTag === 'OddorEven' ? 'current':''}`} onClick={() => setResultsTag('OddorEven')}>Odd or Even</div>
          <div className={`right text-center ${ResultsTag === 'DrawSum' ? 'current':''}`} onClick={() => setResultsTag('DrawSum')}>Draw Sum</div>
        </TagBar>
      </div>
      <div className="barTitle flex flex-center">
        <div className="border"></div>
        <div>Winners List</div>
        <div className="border"></div>
      </div>
      <WinnersListBox className="flex flex-h-between relative">
        {wonList.length? (
          wonList.map((item: any, index: number) => (
            <div key={index} className="item">
              <div className="bar">
                <div className="flex flex-h-between">
                  <div>Ticket Number</div>
                  <div>Date</div>
                </div>
                <div className="flex flex-h-between white">
                  <div>{item.betNumbering}</div>
                  <div>
                    {item.createdAt?.substring(0,10)||''}&nbsp;
                    {item.createdAt?.substring(11,19)||''}
                  </div>
                </div>
              </div>
              <div className="bar">
                <div className="flex flex-h-between">
                  <div>Numbers</div>
                  <div>Multiplier</div>
                </div>
                <div className="flex flex-h-between white">
                  {ResultsTag === 'Number' ?(
                    <div><ItemBall ball={item.contracttype===1?[item.betcontent[0],item.betcontent[1],item.betcontent[2]]:item.betNumbers.split(",")} list={1}></ItemBall></div>
                  ):''}
                  {ResultsTag === 'OddorEven'? (
                    <div>{item.betcontent*1?'Even':'Odd'}</div>
                  ):''}
                  {ResultsTag === 'DrawSum'? (
                    <div>{item.betcontent*1?'Big':'Small'}</div>
                  ):''}
                  <div>{item.multiplier}</div>
                </div>
              </div>
              <div className="bar Label">
                <div className="flex flex-h-between">
                  <div>Hash</div>
                  <div>Reward</div>
                </div>
                <div className="flex flex-h-between white">
                  <div className="flex-1 Abbreviation">{item.transactionHash}</div>
                  <div className="flex-1 text-right">{CalculateBonus(item)}</div>
                </div>
              </div>
              {item.winningStatus? (
                item.rewardStatus? (
                  <div className="Claims flex flex-center">Claim</div>
                ) : (
                  <div className="Claim flex flex-center cursor" onClick={() => ClaimButton(item,index)}>
                    Claim
                    {ClaimLoadding === index? (
                      <img src={loadding} alt="" />
                    ):''}
                  </div>
                )
              ) : ''}
            </div>
          ))
        ):(
          <div className="NotData white">Not Data</div>
        )}
      </WinnersListBox>
      <PagingBar className="flex flex-v-center flex-j-end">
        <img src={icon_prev} alt="" onClick={() => pageButton('prev','won')} />
        <div>{listPage}/{Math.ceil(wonListAll.length/3)}</div>
        <img src={icon_next} alt="" onClick={() => pageButton('next','won')} />
      </PagingBar>
      <div className="barTitle flex flex-center">
        <div className="border"></div>
        <div>All Bets</div>
        <div className="border"></div>
      </div>
      <AllBetsBox className="flex flex-h-between wrap relative">
        {lostList.length ? (
          lostList.map((item: any, index: number) => (
            <div key={index} className="item">
              <div className="flex flex-h-between flex-v-center">
                <div className="white bigSize">#{item.betNumbering}</div>
                  <div className="Data">
                    <div>Date</div>
                    <div className="white">
                      {item.createdAt?.substring(0,10)||''}&nbsp;
                      {item.createdAt?.substring(11,19)||''}
                    </div>
                  </div>
                </div>
                {ResultsTag === 'Number' ?(
                  <div className="ball flex flex-justify-content">
                    <ItemWhiteBall
                      ball={item.contracttype===1?[item.betcontent[0],item.betcontent[1],item.betcontent[2]]:item.betNumbers.split(",")}
                    >
                    </ItemWhiteBall>
                  </div>
                ):''}
                {ResultsTag === 'OddorEven'? (
                  <div className="ball flex flex-justify-content">{item.betcontent*1?'Even':'Odd'}</div>
                ):''}
                {ResultsTag === 'DrawSum'? (
                  <div className="ball flex flex-justify-content">{item.betcontent*1?'Big':'Small'}</div>
                ):''}
                <div className="Hash Abbreviation white cursor">{item.transactionHash}</div>
              </div>
            ))
          ):(
          <div className="NotData white">Not Data</div>
        )}
      </AllBetsBox>
      <PagingBar className="flex flex-v-center flex-j-end">
        <img src={icon_prev} alt="" onClick={() => pageButton('prev','lost')} />
        <div>{lostPage}/{Math.ceil(lostListAll.length/5)}</div>
        <img src={icon_next} alt="" onClick={() => pageButton('next','lost')} />
      </PagingBar>
    </MyPageBox>
  )
}