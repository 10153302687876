import { getAddress } from '@ethersproject/address'
import { Web3Provider } from '@ethersproject/providers'
import { TransactionReceipt } from '@ethersproject/abstract-provider'

export const NetworkContextName = 'GAMELAND'

export function isAddress(value: any): string | false {
    try {
      return getAddress(value)
    } catch {
      return false
    }
  }

export const formatAddress = (address: string, places = 4): string => {
    const validatedAddress = isAddress(address)
    if (!validatedAddress) {
      throw Error(`Invalid address.`)
    }
    const len = address.length
    const start = address.substring(0, places)
    const end = address.substring(len - places, len)
  
    return `${start}...${end}`
}

export const formatting = (address: any, places = 4): string => {
  if (address?.length <= 8) return address
  const len = address?.length
  const start = address.substring(0, places)
  const end = address.substring(len - places, len)
  return `${start}...${end}`
}

export const fetchReceipt = async (transactionHash: string, provider: any): Promise<TransactionReceipt> => {
  const getReceipt = async (hash: string, provider: Web3Provider): Promise<TransactionReceipt> => {
    const receipt = await provider.getTransactionReceipt(hash)
    if (receipt && receipt.blockNumber) {
      // console.log(receipt)
      return receipt
    } else {
      return getReceipt(hash, provider)
    }
  }
  return await getReceipt(transactionHash, provider)
}

export const handleClick = async (CHAIN_ID_HEX: any, RPC_URL: any) => {
  const { ethereum, okxwallet } = window as any
  if (ethereum) {
    try {
      // check if the chain to connect to is installed
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAIN_ID_HEX }] // chainId must be in hexadecimal numbers
      })
    } catch (error: any) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'network',
                chainId: CHAIN_ID_HEX,
                rpcUrls: [RPC_URL]
              }
            ]
          })
        } catch (addError) {
          console.error(addError)
        }
      }
      console.error(error)
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html')
  }
  if (okxwallet) {
    try {
      await okxwallet.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAIN_ID_HEX }],
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to OKX.
      if (switchError.code === 4902) {
        try {
          await okxwallet.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'network',
                chainId: CHAIN_ID_HEX,
                rpcUrls: [RPC_URL]
              }
            ],
          });
        } catch (addError) {
          console.error(addError)
        }
      }
      console.error(switchError)
      // handle other "switch" errors
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    alert('OKX Wallet is not installed.')
  }
}