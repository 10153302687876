import axios from 'axios'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useNetworkLoading } from './NetworkLoading'
import { useNetworkValidator } from './NetworkValidator'

export interface StoreData {
  networkError: boolean
  loading: boolean
  activatingConnector: any
  setActivatingConnector: React.Dispatch<any>
  welletType: any
  setWelletType: React.Dispatch<any>
}

export const StoreContext = createContext({} as StoreData)

export const swrConfig = {
  refreshInterval: 10000,
  refreshWhenHidden: false,
  refreshWhenOffline: false
}

export const http = axios.create({
  timeout: 30000
})

export const lotteryhttp = axios.create({
  timeout: 60000,
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.lottso.fun' : 'http://localhost:8100'
})

export const Store = ({ children }: { children: JSX.Element }) => {
  const networkError = useNetworkValidator()
  const loading = useNetworkLoading()
  const [activatingConnector, setActivatingConnector] = React.useState<any>()
  const [welletType, setWelletType] = React.useState<any>()
  const value = useMemo(() => {
    return {
      networkError,
      loading,
      activatingConnector,
      setActivatingConnector,
      welletType,
      setWelletType
    }
  }, [networkError, loading, activatingConnector])
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}
