import styled from 'styled-components';
import ballIcon1 from '../assets/ball1.png'
import ballIcon2 from '../assets/ball2.png'
import ballIcon3 from '../assets/ball3.png'
import ballIcon4 from '../assets/ball4.png'
import ballIcon5 from '../assets/ball5.png'

const ItemBallBox = styled.div`
  div {
    margin-right: 10px;
    background-repeat: no-repeat;
    color: #fff;
  }
  .px_24 {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }
  .px_40 {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
  }
`
const Ball1 = styled.div`
  background-image: url(${ballIcon1});
`
const Ball2 = styled.div`
  background-image: url(${ballIcon2});
`
const Ball3 = styled.div`
  background-image: url(${ballIcon3});
`
const Ball4 = styled.div`
  background-image: url(${ballIcon4});
`
const Ball5 = styled.div`
  background-image: url(${ballIcon5});
`

export const ItemBall = (data: any) => {
  const { ball, list } = data
  
  return (
    <ItemBallBox className="flex flex-center">
      {ball.length>=1 ? (
        <Ball1 className={`flex flex-center ${list? 'px_24':'px_40'}`}>{ball[0]}</Ball1>
      ):''}
      {ball.length>=2 ? (
        <Ball2 className={`flex flex-center ${list? 'px_24':'px_40'}`}>{ball[1]}</Ball2>
      ):''}
      {ball.length>=3 ? (
        <Ball3 className={`flex flex-center ${list? 'px_24':'px_40'}`}>{ball[2]}</Ball3>
      ):''}
      {ball.length>=4 ? (
        <Ball4 className={`flex flex-center ${list? 'px_24':'px_40'}`}>{ball[3]}</Ball4>
      ):''}
      {ball.length>=5 ? (
        <Ball5 className={`flex flex-center ${list? 'px_24':'px_40'}`}>{ball[4]}</Ball5>
      ):''}
    </ItemBallBox>
  )
}