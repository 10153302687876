import styled from "styled-components"
import { NavLink } from 'react-router-dom'
import Icon5_22 from '../assets/22-ICON.png'
import Icon3D from '../assets/3D-ICON.png'
import Icon5D from '../assets/5D-ICON.png'
import IconCASINO from '../assets/CASINO-icon.png'
import logo from '../assets/logo.png'
import twitter from '../assets/Twitter-Logo.png'
import PolygonImg from '../assets/polygon.svg'
import BSCImg from '../assets/binance.svg'
import { AccountBar } from "./AccountBar"
import { useActiveWeb3React } from "../hooks"
import { useEffect, useState } from "react"
import { handleClick } from "../utils"
import { BSC_CHAIN_ID_HEX, BSC_RPC_URL, POLYGON_CHAIN_ID_HEX, POLYGON_RPC_URL } from "../constants"

const activeClassName = 'ACTIVE'

const Link = styled(NavLink).attrs({
  activeClassName
})`
  outline: none;
  cursor: pointer;
  font-size: 15px;
  height: 80px;
  padding: 0 20%;
  position: relative;
  margin-bottom: 20px;
  color: #5091FF;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  &.${activeClassName} {
    position: relative;
    border-radius: 12px;
    border-radius: 0;
    background: linear-gradient(90deg, #2A3A75 0%, #121631 100%);
    box-shadow: 20px 3px 24px 0px rgba(15,15,38,0.4), -9px -5px 10px 0px rgba(44,53,91,0.5);
    border-radius: 30px;
    :hover,
    :focus {
      color: white;
    }
  }
  :hover,
  :focus {
    color: var(--primary-color);
  }
`
const NavigationBox = styled.div`
  flex: 1;
  min-height: 833px;
  background: #131834;
  box-shadow: 10px 2px 21px 0px rgba(10,10,29,0.3);
  a {
    text-decoration: none;
  }
  .twitter {
    font-weight: bold;
    font-size: 18px;
    color: #5091FF;
    margin: 60px 0;
    margin-left: 20%;
    img {
      width: 25px;
      height: 25px;
      margin-right: 20px;
    }
  }
  .Notyetopened {
    font-size: 18px;
    height: 80px;
    padding: 0 20%;
    margin-bottom: 20px;
    color: #6a6e75;
    cursor: not-allowed;
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
`
const Logo = styled.div`
  height: 150px;
  margin-left: 22%;
`
const ChainChoose = styled.div`
  width: 50%;
  height: 40px;
  background: linear-gradient(90deg, #9741D3 0%, #0F69FF 100%);
  box-shadow: 6px 6px 20px 0px rgba(8,8,29,0.6), -4px -4px 10px 0px rgba(70,84,132,0.5);
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  border: none;
  margin-left: 20%;
  margin-top: 20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .jumpButton {
    width: 100%;
    height: 100%;
  }
  .menu {
    width: 100%;
    background: #2A3A75;
    border-radius: 4px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    padding-top: 10px;
    position: absolute;
    top: 40px;
    div {
      margin-bottom: 10px;
      margin-left: 20px;
      &:hover {
        background-color: #0F69FF;
      }
    }
  }
`

export const Navigation = () => {
  const { account, chainId } = useActiveWeb3React()
  const [visible, setVisible] = useState(false)
  const [chainName, setChainName] = useState('')
  const [chainImg, setChainImg] = useState('')
  useEffect(() => {
    if (chainId === 56) {
      setChainName('BNB chain')
      setChainImg(BSCImg)
    } else if (chainId === 137) {
      setChainName('Polygon')
      setChainImg(PolygonImg)
    }
  }, [chainId])

  return (
    <NavigationBox>
      <Logo className="flex flex-v-center"><img src={logo} alt="" /></Logo>
      <div className="">
        <Link exact to="/">
          <img src={Icon3D} alt="" />
          <span>3D Lottery</span>
        </Link>
        <Link exact to="5DLottery">
          <img src={Icon5D} alt="" />
          <span>5D Lottery</span>
        </Link>
        <Link exact to="5_22Lottery">
          <img src={Icon5_22} alt="" />
          <span>5/22 Lottery</span>
        </Link>
        {/* <div className="Notyetopened flex flex-v-center">
          <img src={Icon3D} alt="" />
          <span>3D Lottery</span>
        </div> */}
        {/* <div className="Notyetopened flex flex-v-center">
          <img src={Icon5D} alt="" />
          <span>5D Lottery</span>
        </div> */}
        <div className="Notyetopened flex flex-v-center">
          <img src={IconCASINO} alt="" />
          <span>CASINO</span>
        </div>
      </div>
      <a href='https://twitter.com/LottSo_lol' target="_blank" rel="noreferrer">
        <div className="twitter flex flex-v-center cursor">
          <img src={twitter} alt="" />
          <div>Twitter</div>
        </div>
      </a>
      <AccountBar></AccountBar>
      {account?.length ? (
        <ChainChoose className="flex flex-center relative">
          <div className="jumpButton flex flex-center cursor" onClick={() => setVisible(!visible)}>
            <img src={chainImg} alt="" />
            <div className="chainName">{chainName}</div>
            {visible? (
              <div className="menu borderbox">
                <div className="flex flex-v-center" onClick={() => handleClick(POLYGON_CHAIN_ID_HEX, POLYGON_RPC_URL)}>
                  <img src={PolygonImg} />
                  Polygon
                </div>
                <div className="flex flex-v-center" onClick={() => handleClick(BSC_CHAIN_ID_HEX, BSC_RPC_URL)}>
                  <img src={BSCImg} alt="" />
                  BNB chain
                </div>
              </div>
            ):''}
          </div>
        </ChainChoose>
      ) : ''}
    </NavigationBox>
  )
}