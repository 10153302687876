// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './all.css'
// import '@rainbow-me/rainbowkit/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom'
import { NetworkContextName } from './utils';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
// import { getDefaultConfig, RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit';
// import { WagmiProvider } from 'wagmi';
// import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// import {
//   polygon,
//   bsc,
//   polygonAmoy
// } from 'wagmi/chains';
import { Store } from './components/Store'

const OWeb3ReactProvider = createWeb3ReactRoot(NetworkContextName)

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

// const config = getDefaultConfig({
//   appName: 'LottSo',
//   projectId: 'YOUR_PROJECT_ID',
//   chains: [polygon, bsc, polygonAmoy],
//   ssr: true, 
// });
// const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <Web3ReactProvider getLibrary={getLibrary}>
      <OWeb3ReactProvider getLibrary={getLibrary}>
        <HashRouter>
          <Store>
            <App />
          </Store>
        </HashRouter>
      </OWeb3ReactProvider>
    </Web3ReactProvider>
    {/* <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider locale="en-US">
          <HashRouter>
            <Store>
              <App />
            </Store>
          </HashRouter>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
