import styled from 'styled-components';
import ballIcon from '../assets/WhiteBall.png'

const ItemBallBox = styled.div`
  div {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    margin-right: 8px;
    background-repeat: no-repeat;
    color: #000;
    font-weight: bold;
    font-size: 14px;
  }
`
const Ball = styled.div`
  background-image: url(${ballIcon});
`

export const ItemWhiteBall = (data: any) => {
  const { ball } = data
  
  return (
    <ItemBallBox className="flex flex-center">
      {ball.length>=1 ? (
        <Ball className="flex flex-center">{ball[0]}</Ball>
      ):''}
      {ball.length>=2 ? (
        <Ball className="flex flex-center">{ball[1]}</Ball>
      ):''}
      {ball.length>=3 ? (
        <Ball className="flex flex-center">{ball[2]}</Ball>
      ):''}
      {ball.length>=4 ? (
        <Ball className="flex flex-center">{ball[3]}</Ball>
      ):''}
      {ball.length>=5 ? (
        <Ball className="flex flex-center">{ball[4]}</Ball>
      ):''}
    </ItemBallBox>
  )
}