import styled from 'styled-components';
import { useCallback, useMemo, useState, useEffect } from 'react';
import BG from '../assets/background.png'
import Icon3D from '../assets/3D-ICON.png'
import loadding from '../assets/loading.svg'
import RandomizeIcon from '../assets/Randomize-icon.png'
import AddTicketIcon from '../assets/Addticket-icon.png'
import clearAllIcon from '../assets/ClearAll-icon.png'
import closeIcon from '../assets/close.png'
import awardIcon from '../assets/award-icon.png'
import icon_1 from '../assets/1.png'
import icon_2 from '../assets/2.png'
import EvenButtn from '../assets/Even-button.png'
import icon_max from '../assets/icon_max.png'
import icon from '../assets/icon_>.png'
import icon1 from '../assets/icon1.png'
import winningIcon from '../assets/winning-icon.png'
import winningListItemBG from '../assets/winningListBg.png'
import AllBetsItemBg from '../assets/AllBetsItemBg.png'
import icon_next from '../assets/icon_NextPage.png'
import icon_prev from '../assets/icon_PrevPage.png'
import {
  AddBet,
  PoolInfo,
  OrderList,
  PreviousWinning,
  ResultBox,
  StageDateBar,
  StageListBar,
  WinnersListBox,
  PagingBar,
  AllBetsBox
} from './Pool5_22';
import { ItemBall } from './ItemBall';
import {
  useActiveWeb3React,
  useControl3DContract,
  useUSDTContract,
  useAsset3DContract
} from '../hooks';
import { polygon3DAsset, polygontest3DAsset } from '../constants';
import { lotteryhttp } from './Store';
import { formatting } from '../utils';
import BigNumber from 'bignumber.js';
import { ItemWhiteBall } from './ItemWhiteBall';

const Pool3Box = styled.div`
  background-image: url(${BG});
  background-size: 100% 360px;
  background-repeat: no-repeat;
  .NotData {
    position: absolute;
    left: 48%;
  }
  .color-4C83DF {
    color: #4C83DF;
  }
  .color-0F69FF {
    color: #0F69FF;
  }
  .drawSun {
    width: 48px;
    height: 20px;
    background: #263467;
    border-radius: 10px;
    color: #4D8BF5;
    border: 1px solid #4D8BF5;
    margin-right: 5px;
  }
  .barTitles {
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 30px;
    .border {
      width: 15px;
      height: 4px;
      background: #0F69FF;
      border-radius: 2px;
    }
    div {
      margin-left: 20px;
    }
  }
`
export const TagBar = styled.div`
  margin: 30px 0;
  width: 520px;
  line-height: 36px;
  background: #263467;
  border-image: linear-gradient(158deg, #3F539E, #3F539E) 10 10;
  border-radius: 20px 20px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  .current {
    background: #0F142E;
  }
  .item {
    flex: 1;
    height: 36px;
    border-right: 1px solid #3F539E;
  }
  .left {
    flex: 1;
    height: 36px;
    border-right: 1px solid #3F539E;
    border-radius: 20px 0px 0px 20px;
  }
  .right {
    flex: 1;
    height: 36px;
    border-radius: 0px 20px 20px 0px;
  }
`
export const NumberBox = styled.div`
`
export const AddNumberTicket = styled.div`
  width: 690px;
  height: 352px;
  background: #131834;
  border-radius: 30px;
  border: 2px solid #263467;
  color: #FFFFFF;
  .SelectNumber {
    margin-top: 30px;
    font-size: 30px;
    font-weight: bold;
    .item {
      margin: 0px 25px;
      .top {
        width: 70px;
        height: 50px;
        background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
        border-radius: 30px 30px 0px 0px;
        line-height: 50px;
      }
      .numberInput {
        width: 70px;
        height: 100px;
        background: #192045;
        padding: 10px;
        input {
          width: 50px;
          height: 80px;
          background: #131834;
          box-shadow: -3px -2px 10px 0px rgba(0,0,0,0.6);
          border-radius: 20px;
          font-family: Times New Roman;
          border: 1px solid #2D386C;
          font-weight: bold;
          font-size: 30px;
          color: #FFFFFF;
        }
        input:focus {
          outline: none;
        }
      }
      .bottom {
        width: 70px;
        height: 50px;
        background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
        border-radius: 0px 0px 30px 30px;
        line-height: 50px;
      }
    }
  }
    .Button {
      margin-top: 30px;
      img {
        margin-left: 10px;
        margin-right: 15px;
      }
      .Randomize {
        width: 160px;
        height: 50px;
        background: #131834;
        box-shadow: 8px 1px 12px 0px rgba(15,15,38,0.6), -5px -3px 10px 0px rgba(70,84,132,0.5);
        border-radius: 30px;
        border: 1px solid #263467;
        margin-right: 70px;
      }
      .AddTicket {
        width: 160px;
        height: 50px;
        background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
        border-radius: 30px;
      }
    }
`
export const AddOddorEvenTicket = styled.div`
  width: 690px;
  color: #FFFFFF;
  font-size: 20px;
  .left {
    width: 300px;
    height: 70px;
    background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
    box-shadow: 8px 1px 12px 0px rgba(15,15,38,0.6), -5px -3px 10px 0px rgba(70,84,132,0.5);
    border-radius: 30px;
  }
  .right {
    width: 300px;
    height: 70px;
    background-image: url(${EvenButtn});
    background-size: 300px 70px;
    background-repeat: no-repeat;
  }
`

export const Pool3 = () => {
  const { account, chainId } = useActiveWeb3React()
  const chainName = chainId===56? 'bsc':chainId===137? 'polygon':chainId===80002?'amoy':'ethtest'
  const AssetAddress = chainId===56? '':chainId===137?polygon3DAsset:polygontest3DAsset
  const USDTContract = useUSDTContract()
  const Control3D = useControl3DContract()
  const Asset3D = useAsset3DContract()
  const [hour, sethours] = useState(0)
  const [minut, setminute] = useState(0)
  const [second, setseconds] = useState(0)
  const [ClaimLoadding, setClaimLoadding] = useState(-1)
  const [winnersListPage, setWinnersListPage] = useState(1)
  const [allBetsPage, setAllBetsPage] = useState(1)
  const [StageListBarSate, setStageListBarSate] = useState(false)
  const [stageDateState, setStageDateState] = useState(false)
  const [waitingState, setWaitingState] = useState(false)
  const [AddBetSate, setAddBetSate] = useState(false)
  const [refresh, setrefresh] = useState(false)
  const [tag, setTag] = useState('Number')
  const [ResultsTag, setResultsTag] = useState('Number')
  const [stopBetTime, setStopBetTime] = useState('')
  const [multiplier, setMultiplier] = useState('1')
  const [numberInput1, setNumberInput1] = useState('0')
  const [numberInput2, setNumberInput2] = useState('0')
  const [numberInput3, setNumberInput3] = useState('0')
  const [currentStage, setCurrentStage] = useState('')
  const [currentStageDate, setCurrentStageDate] = useState('')
  const [ClickTicket, setClickTicket] = useState('')
  const [ticket, setTicket] = useState([] as any)
  const [ticketType, setTicketType] = useState([] as any)
  const [stageListAll, setStageListAll] = useState([] as any)
  const [stageList, setStageList] = useState([] as any)
  const [stageDate, setStageDate] = useState([] as any)
  const [winnersList, setWinnersList] = useState([] as any)
  const [winnersListAll, setWinnersListAll] = useState([] as any)
  const [allBets, setAllBets] = useState([] as any)
  const [allBetsAll, setAllBetsAll] = useState([] as any)
  const [betListAll, setBetListAll] = useState([] as any)
  const [times, settimes] = useState([] as any)
  const [currentStageInfo, setCurrentStageInfo] = useState({} as any)

  useEffect(()=> {
    setTicket([])
    setTicketType([])
    setClickTicket('')
  },[tag])

  useEffect(() => {
    getContractInfo()
  },[chainId])

  useEffect(() => {
    updateCountdown()
  },[stageListAll])

  useEffect(() => {
    if (currentStageDate) {
      const filetrDate = stageListAll.filter((item: any) => {
        return item.datetime.substring(0,10) === currentStageDate
      })
      const arr = [] as any
      filetrDate.map((item: any) => {
        arr.push(item.stage)
      })
      const sortarr = arr.sort((a: any,b: any)=> {return b*1-a*1})
      setStageList(sortarr)
      setCurrentStage(sortarr[0])
    } else {
      setCurrentStage('')
    }
  },[currentStageDate])

  useEffect(() => {
    if (currentStage) {
      getCurrentStageData()
      const info = stageListAll.filter((item: any) => {
        return item.stage === currentStage
      })
      setCurrentStageInfo(info[0])
    } else {
      setCurrentStageInfo({})
    }
  },[currentStage,refresh])

  useEffect(() => {
    setWinnersList(winnersListAll.slice((winnersListPage-1)*3, winnersListPage*3))
  },[winnersListPage])
  useEffect(() => {
    setAllBets(allBetsAll.slice((allBetsPage-1)*5, allBetsPage*5))
  },[allBetsPage])

  useEffect(() => {
    if (betListAll.length) {
      const type = ResultsTag==='Number'? 1: ResultsTag==='OddorEven'? 2 : 3
      const filterWinners = betListAll.filter((item: any) => {
        return item.winningStatus && item.bettype === type
      })
      const sortwon = filterWinners.sort((a: any,b: any)=> {return a.betNumbering*1-b.betNumbering*1})
      setWinnersList(sortwon.slice(0, 3))
      setWinnersListAll(sortwon)
      const filterallBets = betListAll.filter((item: any) => {
        return !item.winningStatus && item.bettype === type
      })
      const sortlost = filterallBets.sort((a: any,b: any)=> {return a.betNumbering*1-b.betNumbering*1})
      setAllBets(sortlost.slice(0, 5))
      setAllBetsAll(sortlost)
    }
  },[ResultsTag,betListAll])

  const BetAmount = useMemo(() => {
    let approvevalue = 0
    ticket.map(() => {
      approvevalue = approvevalue + 2 * Number(multiplier)
    })
    return approvevalue
  }, [ticket, multiplier])

  const CalculateBonus = (item: any) => {
    if (currentStageInfo.overloadmultiples === '0') {
      if (item.winningStatus&&item.bettype===1) {
        return item.multiplier*500
      }
      if (item.winningStatus&&item.bettype===2) {
        return item.multiplier*10
      }
      if (item.winningStatus&&item.bettype===3) {
        return item.multiplier*5
      }
    } else {
      const wei = chainId === 137||chainId === 80002? 1000000: 1000000000000000000
      const over = new BigNumber(currentStageInfo.overloadmultiples).div(wei).toNumber()
      if (item.winningStatus&&item.bettype===1) {
        const val = item.multiplier*500/over
        return new BigNumber(val).times(0.95).toNumber().toFixed(2)
      }
      if (item.winningStatus&&item.bettype===2) {
        const val = item.multiplier*10/over
        return new BigNumber(val).times(0.95).toNumber().toFixed(2)
      }
      if (item.winningStatus&&item.bettype===3) {
        const val = item.multiplier*5/over
        return new BigNumber(val).times(0.95).toNumber().toFixed(2)
      }
    }
  }

  const updateCountdown = () => {
    if (!stageListAll.length) {
      if (times.length) {
        closetime()
      }
      return
    }
    const CountdownHours = stageListAll[stageListAll.length-1].datetime.substring(11, 13)
    const currentTime = stageListAll[stageListAll.length-1].datetime.substring(0, 10)
    const currentDay = stageListAll[stageListAll.length-1].datetime.substring(8, 10)
    const currentHours = new Date().getHours()
    if (currentHours >= CountdownHours*1+1) {
      const stopTime = CountdownHours*1+3===24? `${currentTime.substring(0,8)}${currentDay*1+1} 00:30:00`:`${currentTime} ${CountdownHours*1+3}:30:00`
      const targetTime = CountdownHours*1+3===24? `${currentTime.substring(0,8)}${currentDay*1+1} 01:00:00`:`${currentTime} ${CountdownHours*1+4}:00:00`
      setStopBetTime(stopTime)
      const distance = (new Date(targetTime).getTime()-new Date().getTime())
      if (distance > 0) {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        sethours(hours)
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        setminute(minutes)
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setseconds(seconds)
        const timeout = setTimeout(updateCountdown, 1000);
        const arr = []
        arr.push(timeout)
        settimes(arr)
      }
    } else {
      setWaitingState(true)
      const distance = (new Date(`${currentTime} ${CountdownHours*1+1}:00:00`).getTime()-new Date().getTime())
      if (distance > 0) {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        sethours(hours)
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        setminute(minutes)
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setseconds(seconds)
        const timeout = setTimeout(updateCountdown, 1000);
        const arr = []
        arr.push(timeout)
        settimes(arr)
      } else {
        setWaitingState(false)
      }
    }
  }

  const closetime = () => {
    setStopBetTime('')
    sethours(0)
    setminute(0)
    setseconds(0)
    for (const timer of times) {
      clearTimeout(timer)
    }
  }

  const getContractInfo = async () => {
    const stageListSata = await lotteryhttp.get(`/v0/cj_Settlement_pl3s?chain=${chainName}`)
    const date = [] as any
    stageListSata.data.data.map((item: any) => {
      const filterdata = date.filter((ele: any) => {
        return ele === item.datetime.substring(0,10)
      })
      if (!filterdata.length) {
        date.push(item.datetime.substring(0,10))
      }
    })
    const sortArr = stageListSata.data.data.sort((a: any,b: any)=> {return new Date(a.datetime).getTime()-new Date(b.datetime).getTime()})
    setStageListAll(sortArr)
    const sortdate = date.sort((a: any,b: any)=> {return new Date(b).getTime()-new Date(a).getTime()})
    setStageDate(sortdate)
    setCurrentStageDate(sortdate[0])
  }

  const getCurrentStageData = async () => {
    const data = await lotteryhttp.get(`/v0/betInfo_pl3s?drawNumbering=${currentStage}&chain=${chainName}`)
    setBetListAll(data.data.data)
  }

  const RandomNumber = () => {
    const random1 = Math.floor(Math.random() * 10)
    const random2 = Math.floor(Math.random() * 10)
    const random3 = Math.floor(Math.random() * 10)
    setNumberInput1(random1+'')
    setNumberInput2(random2+'')
    setNumberInput3(random3+'')
  }

  const addNumberTicket = () => {
    if (ticket.length>=10) return
    const betNumber = numberInput1+numberInput2+numberInput3
    setTicket([...ticket,betNumber])
    setTicketType([...ticketType,1])
  }

  const addOddorEvenTicket = (type: string) => {
    if (ticket.length>=10) return
    setClickTicket(type)
    if (type === 'odd' || type === 'even') {
      setTicket([...ticket,type==='odd'? 0:1])
      setTicketType([...ticketType,2])
    }
    if (type === 'big' || type === 'small') {
      setTicket([...ticket,type==='small'? 0:1])
      setTicketType([...ticketType,3])
    }
  }

  const NumberAdd = (index: number) => {
    if (index===1) {
      if (Number(numberInput1)>=9) return
      setNumberInput1(`${Number(numberInput1)+1}`)
    }
    if (index===2) {
      if (Number(numberInput2)>=9) return
      setNumberInput2(`${Number(numberInput2)+1}`)
    }
    if (index===3) {
      if (Number(numberInput3)>=9) return
      setNumberInput3(`${Number(numberInput3)+1}`)
    }
  }

  const NumberMinus = (index: number) => {
    if (index===1) {
      if (Number(numberInput1)<=0) return
      setNumberInput1(`${Number(numberInput1)-1}`)
    }
    if (index===2) {
      if (Number(numberInput2)<=0) return
      setNumberInput2(`${Number(numberInput2)-1}`)
    }
    if (index===3) {
      if (Number(numberInput3)<=0) return
      setNumberInput3(`${Number(numberInput3)-1}`)
    }
  }

  const deleteTicket = (index: any) => {
    const filteritem = ticket.splice(index,1)
    const data = ticket.filter((ele: any) => {
      return `${ele}` !== `${filteritem}`
    })
    setTicket(data)
    setTicketType(ticketType.slice(0, ticketType.length-1))
  }

  const pageButton = (dataname: string, type: string) => {
    if (dataname === 'WinnersList') {
      if (type === 'next') {
        if (winnersListPage>=Math.ceil(winnersListAll.length/3)) return
        setWinnersListPage(winnersListPage+1)
      }
      if (type === 'prev') {
        if (winnersListPage<=1) return
        setWinnersListPage(winnersListPage-1)
      }
    }
    if (dataname === 'AllBets') {
      if (type === 'next') {
        if (allBetsPage>=Math.ceil(allBetsAll.length/5)) return
        setAllBetsPage(allBetsPage+1)
      }
      if (type === 'prev') {
        if (allBetsPage<=1) return
        setAllBetsPage(allBetsPage-1)
      }
    }
  }

  const AddBetButton = async () => {
    if (!ticket.length||!multiplier) return
    if (hour===0&&minut<=30||waitingState) return
    if (AddBetSate) return
    setAddBetSate(true)
    const Multipliers = [] as any
    let approvevalue = 0
    ticket.map(() => {
      Multipliers.push(multiplier)
      approvevalue = approvevalue + 2 * Number(multiplier)
    })
    let index = 0
    console.log(ticketType,ticket,Multipliers)
    try {
      const approveval = chainId===137||chainId === 80002? approvevalue*1000000 : approvevalue*1000000000000000000
      const approvetx = await USDTContract?.approve(AssetAddress,approveval)
      USDTContract?.on('Approval', async (from,to,value,events)=>{
        console.log(from,to,`${value}`)
        if (Number(`${value}`)===approveval && from.toLowerCase() === account?.toLowerCase()&& to.toLowerCase() === AssetAddress?.toLowerCase()) {
          try {
            console.log(ticket,Multipliers)
            await Control3D?.batchbet(ticketType,ticket,Multipliers)
            USDTContract?.removeAllListeners()
          } catch (error) {
            setAddBetSate(false)
            USDTContract?.removeAllListeners()
            Asset3D?.removeAllListeners()
          }
        }
      })
      Asset3D?.on('Betting', (from,value,hash,type,drawNumbering,betNumbering,transactionInfo)=>{
        console.log(from,index,ticket.length)
        if (from.toLowerCase() === account?.toLowerCase()&&index<ticket.length) {
          console.log( `${drawNumbering}`,`${betNumbering}`)
          const list = {
            userAddress: account,
            drawNumbering: `${drawNumbering}`,
            betNumbering: `${betNumbering}`,
            betcontent: `${ticket[index]}`,
            multiplier: multiplier,
            bettype: ticketType[0],
            transactionHash: transactionInfo.transactionHash,
            zcaddress: AssetAddress,
            chain: chainName
          }
          lotteryhttp.post('/v0/betInfo_pl3s', list)
          setTicket([])
          setTicketType([])
          setMultiplier('1')
          setAddBetSate(false)
          setClickTicket('')
          index= index+1
          Asset3D?.removeAllListeners()
        }
      })
    } catch (error) {
      setAddBetSate(false)
      USDTContract?.removeAllListeners()
      Asset3D?.removeAllListeners()
    }
  }

  const ClaimButton = async (item: any, index: number) => {
    if (ClaimLoadding>=0) return
    setClaimLoadding(index)
    try {
      const deposited = await Control3D?.drawreward(item.drawNumbering,item.betNumbering)
      Asset3D?.on('drawreward', (from,value,betnumber,betstage)=>{
        // console.log(from,`${betnumber}`,`${betstage}`)
        if (
          from.toLowerCase() === account?.toLowerCase()
          &&`${betnumber}`===item.betNumbering
          &&`${betstage}`===item.drawNumbering
        ) {
          setClaimLoadding(-1)
          lotteryhttp.put(`/v0/betInfo_pl3s/${item.id}`,{rewardStatus: 1}).then(() => setrefresh(!refresh))
          Asset3D?.removeAllListeners('drawreward')
        }
      })
    } catch (error) {
      setClaimLoadding(-1)
      Asset3D?.removeAllListeners()
    }
  }

  const NumberInput1Change = useCallback((ele: any) => {
    let val = ele.currentTarget.value
    if (val.length > 1) {
      val = val.substr(0, 1);
    }
    if (!/^\d*$/.test(val)) {
      val = val.replace(/\D/,'')
    }
    if (val*1>=0&& val*1<=9) {
      setNumberInput1(val)
    }
    if (val==='') {
      setNumberInput1('')
    }
  }, [])
  const NumberInput2Change = useCallback((ele: any) => {
    let val = ele.currentTarget.value
    if (!/^\d*$/.test(val)) {
      val = val.replace(/\D/,'')
    }
    if (val*1>=0&& val*1<=9) {
      setNumberInput2(val)
    }
    if (val==='') {
      setNumberInput2('')
    }
  }, [])
  const NumberInput3Change = useCallback((ele: any) => {
    let val = ele.currentTarget.value
    if (!/^\d*$/.test(val)) {
      val = val.replace(/\D/,'')
    }
    if (val*1>=0&& val*1<=9) {
      setNumberInput3(val)
    }
    if (val==='') {
      setNumberInput3('')
    }
  }, [])
  const MultiplierChange = useCallback((ele: any) => {
    let val = ele.currentTarget.value
    if (!/^\d*$/.test(val)) {
      val = val.replace(/\D/,'')
    }
    if (val*1>0&& val*1<=20) {
      setMultiplier(val)
    }
    if (val==='') {
      setMultiplier('')
    }
  }, [])

  return (
    <Pool3Box className="main">
      {tag!=='Results'? (
        <PoolInfo>
          <div className="poolName flex flex-v-center">
            <img src={Icon3D} alt="" />
            3D Lottery
          </div>
          <div className="time">Stop Betting Time <span>{stopBetTime}</span></div>
          <div className="time">
            { waitingState?'Waiting To Start':'Next Draw Starts In '}
            <span>{hour}h:{minut}m:{second}s</span>
            {!hour&&!minut&&!second ? (
              <span className="Clearing">Clearing</span>
            ):''}
          </div>
          <div className="Prize flex flex-center">Recent Winning Prize <span>${tag==='Number'? '500':'10'}</span></div>
        </PoolInfo>
      ):''}
      <TagBar className="flex">
        <div className={`left text-center ${tag === 'Number' ? 'current':''}`} onClick={() => setTag('Number')}>Number</div>
        <div className={`item text-center ${tag === 'OddorEven' ? 'current':''}`} onClick={() => setTag('OddorEven')}>Odd or Even</div>
        <div className={`item text-center ${tag === 'DrawSum' ? 'current':''}`} onClick={() => setTag('DrawSum')}>Draw Sum</div>
        <div className={`right text-center ${tag === 'Results' ? 'current':''}`} onClick={() => setTag('Results')}>Results</div>
      </TagBar>
      {tag!=='Results'? (
        <NumberBox className="flex flex-h-between">
          <div>
            {tag === 'Number' ? (
              <AddNumberTicket>
                <div className="SelectNumber flex flex-justify-content">
                  <div className="item">
                    <div className="top text-center cursor" onClick={()=>NumberAdd(1)}>+</div>
                    <div className="numberInput borderbox">
                      <input
                        className="borderbox text-center"
                        type="text"
                        value={numberInput1}
                        onChange={NumberInput1Change}
                      />
                    </div>
                    <div className="bottom text-center cursor" onClick={()=>NumberMinus(1)}>-</div>
                  </div>
                  <div className="item">
                    <div className="top text-center cursor" onClick={()=>NumberAdd(2)}>+</div>
                    <div className="numberInput borderbox">
                      <input
                        className="borderbox text-center"
                        type="text"
                        value={numberInput2}
                        onChange={NumberInput2Change}
                      />
                    </div>
                    <div className="bottom text-center cursor" onClick={()=>NumberMinus(2)}>-</div>
                  </div>
                  <div className="item">
                    <div className="top text-center cursor" onClick={()=>NumberAdd(3)}>+</div>
                    <div className="numberInput borderbox">
                      <input
                        className="borderbox text-center"
                        type="text"
                        value={numberInput3}
                        onChange={NumberInput3Change}
                      />
                    </div>
                    <div className="bottom text-center cursor" onClick={()=>NumberMinus(3)}>-</div>
                  </div>
                </div>
                <div className="Button flex flex-justify-content">
                  <div className="Randomize flex flex-v-center cursor" onClick={RandomNumber}>
                    <img src={RandomizeIcon} alt="" />
                    Randomize
                  </div>
                  <div className="AddTicket flex flex-v-center cursor" onClick={addNumberTicket}>
                    <img src={AddTicketIcon} alt="" />
                    Add 1 ticket
                  </div>
                </div>
              </AddNumberTicket>
            ):''}
            {tag === 'OddorEven'||tag === 'DrawSum'? (
              <AddOddorEvenTicket className="flex flex-v-center flex-h-between">
                <div className={`${ClickTicket==='odd'||ClickTicket==='big'? 'right':'left'} flex flex-center cursor`} onClick={() =>addOddorEvenTicket(tag === 'OddorEven'?'odd':'big')}>
                  {tag === 'OddorEven'?'Odd':'Big (19 ~ 27)'}
                </div>
                <div className={`${ClickTicket==='even'||ClickTicket==='small'? 'right':'left'} flex flex-center cursor`} onClick={() =>addOddorEvenTicket(tag === 'OddorEven'?'even':'small')}>
                  {tag === 'OddorEven'?'Even':'Small ( 0 ~ 8)'}
                </div>
              </AddOddorEvenTicket>
            ):''}
            <OrderList>
              <div className="color-0F69FF">Tickets</div>
              <div className="flex flex-j-end">
                <div className="clearAll cursor flex flex-v-center" onClick={() => {setTicket([]);setTicketType([])}}>
                  <img src={clearAllIcon} alt="" />&nbsp;Clear All
                </div>
              </div>
              <div className="list">
                {ticket.length? (
                  ticket.map((item: any, index: number) => (
                    <div key={index} className="listItem flex flex-v-center flex-around">
                      <div>#{index+1}</div>
                      {tag === 'Number' ?(
                        <ItemBall ball={[item[0],item[1],item[2]]}></ItemBall>
                      ):''}
                      {tag === 'OddorEven'? (
                        <div>{item?'Even':'Odd'}</div>
                      ):''}
                      {tag === 'DrawSum'? (
                        <div>{item?'Big':'Small'}</div>
                      ):''}
                      <img src={closeIcon} className="cursor" alt="" onClick={() => deleteTicket(index)} />
                    </div>
                  ))
                ):(
                  <div className="text-center color-0F69FF">No tickets added</div>
                )}
              </div>
            </OrderList>
          </div>
          <div>
            <AddBet className="borderbox">
              <div className="title color-0F69FF">Multiplier <span>(Up to 20x)</span></div>
              <div className="flex">
                <div className="input flex-center">
                  <input type="text" onChange={MultiplierChange} value={multiplier} />
                </div>
              </div>
              <div className="title color-0F69FF">Bet Amount</div>
              <div className="flex">
                <div className="input flex-center">$ 2.00</div>
              </div>
              <div className="USDTResults flex flex-h-between">
                <div className="color-0F69FF">Total Bet Amount</div>
                <div>{BetAmount} USDT</div>
              </div>
              <div className="flex flex-h-between">
                <div className={`Button flex flex-center ${hour===0&&minut<=30||waitingState? 'unavailable not-allowed':'available cursor'}`} onClick={AddBetButton}>
                  Add Bet 
                  {AddBetSate? (
                    <img src={loadding} alt="" />
                  ):''}
                </div>
              </div>
            </AddBet>
            <PreviousWinning>
              <div className="title flex flex-v-center">
                <img src={awardIcon} alt="" />
                <div>Previous Winning Numbers</div>
              </div>
              <div className="item">
                <div className="flex flex-around flex-v-center">
                  <img src={icon_1} alt="" />
                  <div>
                    <div className="flex flex-v-center">
                      <div className="drawSun flex flex-center">
                        {currentStageInfo?.betresults?.split(",")[1]*1===1?'Even':currentStageInfo?.betresults?.split(",")[1]*1===0?'Odd':''}
                      </div>
                      <div className="drawSun flex flex-center">
                        {currentStageInfo?.betresults?.split(",")[2]*1===1?'Big':currentStageInfo?.betresults?.split(",")[2]*1===0?'Small':''}
                      </div>
                      <div>
                        {currentStageInfo?.datetime?.substring(0,10)||''}&nbsp;
                        {currentStageInfo?.datetime?.substring(11,19)||''}
                      </div>
                    </div>
                    <div className="ball flex flex-j-start">
                      <ItemBall ball={currentStageInfo?.betresults?.split(",")[0]|| []}></ItemBall>
                    </div>
                    <div className="Mistake">Pool Number <span>(Correct/Mistake): 3 / 3</span></div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="flex flex-around flex-v-center">
                  <img src={icon_2} alt="" />
                  <div>
                    <div className="flex flex-v-center">
                      <div className="drawSun flex flex-center">
                        {stageListAll[stageListAll.length-2]?.betresults?.split(",")[1]*1===1?'Even':stageListAll[stageListAll.length-2]?.betresults?.split(",")[1]*1===0?'Odd':''}
                      </div>
                      <div className="drawSun flex flex-center">
                        {stageListAll[stageListAll.length-2]?.betresults?.split(",")[2]*1===1?'Big':stageListAll[stageListAll.length-2]?.betresults?.split(",")[2]*1===0?'Small':''}
                      </div>
                      <div className="text-right">
                        {stageListAll[stageListAll.length-2]?.datetime?.substring(0,10)||''}&nbsp;
                        {stageListAll[stageListAll.length-2]?.datetime?.substring(11,19)||''}
                      </div>
                    </div>
                    
                    <div className="ball flex flex-j-start">
                      <ItemBall ball={stageListAll[stageListAll.length-2]?.betresults?.split(",")[0]|| []}></ItemBall>
                    </div>
                    <div className="Mistake">Pool Number <span>(Correct/Mistake): 3 / 3</span></div>
                  </div>
                </div>
              </div>
            </PreviousWinning>
          </div>
        </NumberBox>
      ):''}
      {tag==='Results'? (
        <ResultBox>
          <div className="selectEncoding flex flex-v-center relative">
            <div>Game ID</div>
            <div className="icon flex flex-center" onClick={() => setStageDateState(!stageDateState)}>
              <img className="cursor" src={icon_max} alt="" />
            </div>
            {stageDateState? (
              <StageDateBar className="stageDateBar borderbox">
                {stageDate.length? (
                  stageDate.map((item: any, index: number) => (
                    <div key={index} className="item text-center cursor" onClick={() => {setCurrentStageDate(item);setStageDateState(false)}}>{item}</div>
                  ))
                ):''}
              </StageDateBar>    
            ):''}
            <div className="Encoding flex flex-h-between relative">
              <div className="flex flex-center">{currentStage}</div>
              <div className="SwitchEncoding flex flex-center cursor" onClick={() => setStageListBarSate(!StageListBarSate)}>
                <img src={icon} alt="" />
              </div>
              {StageListBarSate&&!stageDateState? (
                <StageListBar>
                  {stageList.length? (
                    stageList.map((item: any, index: number) => (
                      <div key={index} className="item text-center cursor" onClick={() => {setCurrentStage(item);setStageListBarSate(false)}}>{item}</div>
                    ))
                  ):''}
                </StageListBar>
              ):''}
            </div>
          </div>
          <div className="winningInfo flex">
            <div className="flex-1">
              <div className="Drawtime">
                Draw time:&nbsp;
                {currentStageInfo?.datetime?.substring(0,10)||''}&nbsp;
                {currentStageInfo?.datetime?.substring(11,19)||''}
                </div>
              <div className="LUCKY flex flex-center"><ItemBall ball={currentStageInfo?.betresults?.split(",")[0]|| []}></ItemBall></div>
            </div>
            <div className="total flex-1">
              <div className="flex flex-center">
                <img src={icon1} alt="" />
                Total Tickets Sold This Round: <span>{currentStageInfo?.betquantity|| 0}</span>
              </div>
              <div className="flex flex-center">
                <img src={winningIcon} alt="" />
                Total Winning Tickets In This Round: <span>{currentStageInfo?.winers|| 0}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-justify-content">
            <TagBar className="flex">
              <div className={`left text-center ${ResultsTag === 'Number' ? 'current':''}`} onClick={() => setResultsTag('Number')}>Number</div>
              <div className={`item text-center ${ResultsTag === 'OddorEven' ? 'current':''}`} onClick={() => setResultsTag('OddorEven')}>Odd or Even</div>
              <div className={`right text-center ${ResultsTag === 'DrawSum' ? 'current':''}`} onClick={() => setResultsTag('DrawSum')}>Draw Sum</div>
            </TagBar>
          </div>
          <div className="barTitles flex flex-center">
            <div className="border"></div>
            <div>Winners List</div>
            <div className="border"></div>
          </div>
          <WinnersListBox className="flex flex-h-between relative">
            {winnersList.length? (
              winnersList.map((item: any, index: number) => (
                <div key={index} className="item">
                  <div className="bar">
                    <div className="flex flex-h-between">
                      <div>Ticket Number</div>
                      <div>Date</div>
                    </div>
                    <div className="flex flex-h-between white">
                      <div>{item.betNumbering}</div>
                      <div>
                        {item.createdAt?.substring(0,10)||''}&nbsp;
                        {item.createdAt?.substring(11,19)||''}
                      </div>
                    </div>
                  </div>
                  <div className="bar">
                    <div className="flex flex-h-between">
                      <div>{ResultsTag === 'Number' ?'Numbers':ResultsTag === 'OddorEven'? 'Odd/Even':'Big/Small'}</div>
                      <div>Multiplier</div>
                    </div>
                    <div className="flex flex-h-between white">
                      {ResultsTag === 'Number' ?(
                        <div><ItemBall ball={[item.betcontent[0],item.betcontent[1],item.betcontent[2]]} list={1}></ItemBall></div>
                      ):''}
                      {ResultsTag === 'OddorEven'? (
                        <div>{item.betcontent*1?'Even':'Odd'}</div>
                      ):''}
                      {ResultsTag === 'DrawSum'? (
                        <div>{item.betcontent*1?'Big':'Small'}</div>
                      ):''}
                      <div>{item.multiplier}</div>
                    </div>
                  </div>
                  <div className="bar Label">
                    <div className="flex flex-h-between">
                      <div>Owner</div>
                      <div>Reward</div>
                    </div>
                    <div className="flex flex-h-between white">
                      <div>{formatting(item.userAddress)}</div>
                      <div>{CalculateBonus(item)}</div>
                    </div>
                  </div>
                  {item.userAddress === account? (
                    item.rewardStatus? (
                      <div className="Claims flex flex-center">Claim</div>
                    ) : (
                      <div className="Claim flex flex-center cursor" onClick={() => ClaimButton(item,index)}>
                        Claim
                        {ClaimLoadding === index? (
                          <img src={loadding} alt="" />
                        ):''}
                      </div>
                    )
                  ) : ''}
                </div>
              ))
            ):(
              <div className="NotData white">Not Data</div>
            )}
          </WinnersListBox>
          <PagingBar className="flex flex-v-center flex-j-end">
            <img src={icon_prev} alt="" onClick={() => pageButton('WinnersList','prev')} />
            <div>{winnersListPage}/{Math.ceil(winnersListAll.length/3)}</div>
            <img src={icon_next} alt="" onClick={() => pageButton('WinnersList','next')} />
          </PagingBar>
          <div className="barTitle flex flex-center">
            <div className="border"></div>
            <div>All Bets</div>
            <div className="border"></div>
          </div>
          <AllBetsBox className="flex flex-h-between wrap relative">
            {allBets.length ? (
              allBets.map((item: any, index: number) => (
                <div key={index} className="item">
                  <div className="flex flex-h-between flex-v-center">
                    <div className="white bigSize">#{item.betNumbering}</div>
                    <div className="Data">
                      <div>Date</div>
                      <div className="white">
                        {item.createdAt?.substring(0,10)||''}&nbsp;
                        {item.createdAt?.substring(11,19)||''}
                      </div>
                    </div>
                  </div>
                  {ResultsTag === 'Number' ?(
                    <div className="ball flex flex-justify-content"><ItemWhiteBall ball={[item.betcontent[0],item.betcontent[1],item.betcontent[2]]}></ItemWhiteBall></div>
                  ):''}
                  {ResultsTag === 'OddorEven'? (
                    <div className="ball flex flex-justify-content">{item.betcontent*1?'Even':'Odd'}</div>
                  ):''}
                  {ResultsTag === 'DrawSum'? (
                    <div className="ball flex flex-justify-content">{item.betcontent*1?'Big':'Small'}</div>
                  ):''}
                  <div className="Hash Abbreviation white cursor">{item.transactionHash}</div>
                </div>
              ))
            ):(
              <div className="NotData white">Not Data</div>
            )}
          </AllBetsBox>
          <PagingBar className="flex flex-v-center flex-j-end">
            <img src={icon_prev} alt="" onClick={() => pageButton('AllBets','prev')} />
            <div>{allBetsPage}/{Math.ceil(allBetsAll.length/5)}</div>
            <img src={icon_next} alt="" onClick={() => pageButton('AllBets','next')} />
          </PagingBar>
        </ResultBox>
      ):''}
    </Pool3Box>
  )
}