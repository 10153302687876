import styled from 'styled-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
// import { parseEther } from '@ethersproject/units'
// import Web3 from 'web3'
import BigNumber from 'bignumber.js'
// import {
//   useChainId,
//   useAccount,
//   useWaitForTransactionReceipt,
//   useWriteContract
// } from 'wagmi'
import { ItemBall } from './ItemBall'
import BG from '../assets/background.png'
import Icon5_22 from '../assets/22-ICON.png'
import refreshIcon from '../assets/refresh-icon.png'
import icon from '../assets/icon_>.png'
import icon_max from '../assets/icon_max.png'
import icon_1 from '../assets/1.png'
import icon_2 from '../assets/2.png'
import clearAllIcon from '../assets/ClearAll-icon.png'
import closeIcon from '../assets/close.png'
import awardIcon from '../assets/award-icon.png'
import rectangle from '../assets/rectangle.png'
import LUCKY from '../assets/LUCKY_NUMBERS.png'
import icon1 from '../assets/icon1.png'
import winningIcon from '../assets/winning-icon.png'
import winningListItemBG from '../assets/winningListBg.png'
import AllBetsItemBg from '../assets/AllBetsItemBg.png'
import icon_next from '../assets/icon_NextPage.png'
import icon_prev from '../assets/icon_PrevPage.png'
import loadding from '../assets/loading.svg'
import { useActiveWeb3React, useControl22_5Contract, useUSDTContract, useAsset22_5Contract } from '../hooks';
import { formatting } from '../utils';
import { polygontest22_5Asset, polygon22_5Asset, bsc22_5Asset } from '../constants';
import { lotteryhttp } from './Store';
import { ItemWhiteBall } from './ItemWhiteBall';
import { toastify } from './Toastify'
import { abi } from './Abi'


const Pool5_22Box = styled.div`
  background-image: url(${BG});
  background-size: 100% 360px;
  background-repeat: no-repeat;
  .NotData {
    position: absolute;
    left: 48%;
  }
`

export const PoolInfo = styled.div`
  margin-top: 40px;
  font-size: 16px;
  span {
    color: #FFFFFF;
    margin-left: 19px;
  }
  .poolName {
    font-size: 26px;
    color: #FFFFFF;
    font-weight: bold;
    img {
      width: 50px;
      height: 50px;
      margin-right: 6px;
    }
  }
  .time {
    margin-top: 26px;
    color: #4C83DF;
  }
  .Prize {
    margin-top: 26px;
    width: 300px;
    height: 36px;
    background: linear-gradient(90deg, #0F69FF 0%, #9741D3 100%);
    box-shadow: 6px 6px 20px 0px rgba(8,8,29,0.6), -4px -4px 10px 0px rgba(70,84,132,0.5);
    border-radius: 30px;
    color: #FFFFFF;
    span {
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
    }
  }
  .Clearing {
    padding: 2px 20px;
    color: #fff;
    background: #263467;
    border-radius: 20px;
    font-size: 14px;
  }
  .Waiting {
    padding: 10px 20px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
  }
`

export const TagBar = styled.div`
  margin-top: 30px;
  width: 260px;
  line-height: 36px;
  background: #263467;
  border-image: linear-gradient(158deg, #3F539E, #3F539E) 10 10;
  border-radius: 20px 20px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  .current {
    background: #0F142E;
  }
  .Number {
    flex: 1;
    height: 36px;
    border-radius: 20px 0px 0px 20px;
  }
  .Results {
    flex: 1;
    height: 36px;
    border-radius: 0px 20px 20px 0px;
  }
`

const NumberBox = styled.div`
  margin-top: 30px;
  .color-4C83DF {
    color: #4C83DF;
  }
  .color-0F69FF {
    color: #0F69FF;
  }
` 

const AddTicket = styled.div`
  width: 692px;
  height: 352px;
  margin-right: 20px;
  font-size: 16px;
  span {
    margin-left: 19px;
    color: #FFFFFF;
  }
  .ball {
    padding-top: 10px;
    position: relative;
    margin-bottom: 100px;
    .selected {
      background: #131834;
      box-shadow: -3px -2px 10px 0px rgba(0,0,0,0.6);
      border-radius: 20px;
    }
    div {
      width: 45px;
      height: 45px;
      line-height: 45px;
      background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
      border-radius: 15px;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      margin-top: 12px;
      margin-right: 12px;
    }
  }
  .refreshButton {
    width: 48px;
    height: 48px;
    background: #263467;
    box-shadow: 20px 3px 24px 0px rgba(15,15,38,0.4), -9px -5px 10px 0px rgba(44,53,91,0.5);
    border-radius: 50%;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .selectedBalls {
    width: 360px;
    height: 48px;
    background: #131834;
    box-shadow: -3px -2px 15px 0px rgba(0,0,0,0.7);
    border-radius: 30px;
    margin: 0 10px;
  }
`

export const AddBet = styled.div`
  width: 358px;
  background: #192045;
  border-radius: 10px;
  font-size: 16px;
  color: #FFFFFF;
  padding: 26px 19px;
  .title {
    font-size: 20px;
    margin-bottom: 8px;
    span {
      color: #6a6e75;
      font-size: 14px;
    }
  }
  .input {
    width: 100%;
    height: 30px;
    background: #131834;
    box-shadow: -3px -2px 10px 0px rgba(0,0,0,0.6);
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 10px;
    input {
      width: 90%;
      padding-left: 10px;
      color: #FFFFFF;
      font-size: 16px;
      background: #131834;
      border: none;
      font-family: Times New Roman;
    }
    input:focus {
      outline: none;
      border: none;
    }
  }
  .icons {
    width: 46px;
    height: 46px;
    background: #263467;
    box-shadow: 20px 3px 24px 0px rgba(15,15,38,0.4), -9px -5px 10px 0px rgba(44,53,91,0.5);
    border-radius: 50%;
    margin-left: 10px;
  }
  .USDTResults {
    margin-bottom: 20px;
  }
  .BTC {
    margin-right: 45px;
    margin-bottom: 10px;
  }
  .available {
    background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
  }
  .unavailable {
    background: #131834;
  }
  .Button {
    width: 100%;
    height: 60px;
    box-shadow: 8px 1px 12px 0px rgba(15,15,38,0.6), -5px -3px 10px 0px rgba(70,84,132,0.5);
    border-radius: 20px;
    border-image: linear-gradient(63deg, #4A60B1, #1F2B58) 10 10;
    img {
      widh: 40px;
      height: 40px;
    }
  }
`

export const OrderList = styled.div`
  width: 692px;
  min-height: 400px;
  background: #192045;
  border-radius: 30px;
  padding: 30px 29px;
  box-sizing: border-box;
  margin-top: 24px;
  color: #FFFFFF;
  .list {
    width: 75%;
    height: 300px;
    margin: auto;
    overflow-y: auto;
    .clearAll {
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        margin-right: 8px;
      }
    }
    .listItem {
      width: 98%;
      height: 60px;
      background: #263567;
      border-radius: 20px;
      margin-bottom: 10px;
    }
  }
`
export const PreviousWinning = styled.div`
  width: 358px;
  box-sizing: border-box;
  margin-top: 20px;
  .title {
    color: #FFFFFF;
  }
  .item {
    width: 100%;
    height: 120px;
    background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
    border-radius: 30px;
    padding: 10px 20px;
    box-sizing: border-box;
    color: #fff;
    margin-bottom: 15px;
    font-size: 14px;
    img {
      width: 21px;
      height: 39px;
    }
    .ball {
      margin-top: 12px
    }
    .Mistake {
      margin-top: 12px;
      span {
        color: #4D8BF5;
      }
    }
  }
`

export const ResultBox = styled.div`
  margin-top: 30px;
  .selectEncoding {
    font-size: 20px;
    color: #5091FF;
    .icon {
      width: 45px;
      height: 45px;
      background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
      box-shadow: 10px 2px 16px 0px rgba(15,15,38,0.4), -7px -4px 20px 0px rgba(70,84,132,0.5);
      border-radius: 22px;
      margin: 0 20px;
    }
    .Encoding {
      width: 280px;
      height: 50px;
      border-radius: 20px;
      border: 1px solid;
      padding-left: 60px;
      box-sizing: border-box;
      .SwitchEncoding {
        width: 70px;
        height: 48px;
        background: #263467;
        border-radius: 5px 20px 20px 5px;
        border: 1px solid;
        margin-top: -1px;
      }
    }
  }
  .winningInfo {
    width: 96%;
    background-image: url(${rectangle});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 30px;
    padding: 20px 23px;
    color: #4D8BF5;
    .LUCKY {
      width: 70%;
      height: 90px;
      background-image: url(${LUCKY});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 20px;
      padding-left: 120px;
    }
    .total {
      div {
        margin-top: 20px;      
      }
      img {
        margin-right: 10px;
      }
      span {
        color: #fff;
        margin-left: 5px;
      }
    }
  }
  .barTitle {
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 30px;
    .border {
      width: 15px;
      height: 4px;
      background: #0F69FF;
      border-radius: 2px;
    }
    div {
      margin-left: 20px;
    }
  }
`
export const StageListBar = styled.div`
  width: 280px;
  height: 250px;
  border-radius: 20px;
  border: 1px solid;
  background: #131834;
  position: absolute;
  left: 0px;
  top: 50px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .item {
    margin-bottom: 10px;
    &:hover {
      background-color: #2a3a75;
    }
  }
`
export const StageDateBar = styled.div`
  padding: 10px;
  width: 155px;
  height: 250px;
  border-radius: 20px;
  border: 1px solid;
  background: #131834;
  position: absolute;
  left: 0px;
  top: 50px;
  .item {
    margin-bottom: 10px;
    &:hover {
      background-color: #2a3a75;
    }
  }      
`
export const WinnersListBox = styled.div`
  min-height: 80px;
  .item {
    width: 27%;
    height: 240px;
    background-image: url(${winningListItemBG});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #4D8BF5;
    padding: 20px;
    .bar {
      margin-bottom: 15px;
      div {
        margin-bottom: 3px;
      }
    }
    .Label {
      background: linear-gradient(90deg, #202854 0%, #263467 50%, #202854 100%);
    }
    .Claims {
      width: 90%;
      height: 40px;
      background: #131834;
      border-radius: 20px;
      margin: auto;
      color: #263467;
    }
    .Claim {
      width: 90%;
      height: 40px;
      background: linear-gradient(122deg, #3F539E 0%, #263467 100%);
      border-radius: 20px;
      margin: auto;
      color: #FEFEFE;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`
export const AllBetsBox = styled.div`
  min-height: 80px;
  .item {
    width: 16%;
    height: 120px;
    background-image: url(${AllBetsItemBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 15px;
    color: #4D8BF5;
    font-size: 19px;
    .Data {
      font-size: 14px;
      text-align: right;
    }
    .ball {
      margin: 10px 0;
    }
    .Hash {
      margin-top: 28px;
      font-size: 14px;
    }
  }
`

export const PagingBar = styled.div`
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  div {
    margin: 0 10px;
  }
  img {
    cursor: pointer;
  }
`

const BallItems = (index: number) => {
  return {
    ball: index+1,
    state:false
  }
}



export const Pool5_22 = () => {
  const NumberBall = (Array.from({ length: 22 },(item: any,index: number) => BallItems(index)))
  const { account, chainId } = useActiveWeb3React()
  const chainName = chainId===56? 'bsc':chainId===137? 'polygon':chainId===80002?'amoy':'ethtest'
  const AssetAddress = chainId===56? bsc22_5Asset:chainId===137?polygon22_5Asset:polygontest22_5Asset
  const USDTContract = useUSDTContract()
  const Control22_5 = useControl22_5Contract()
  const Asset22_5 = useAsset22_5Contract()
  const [refresh, setrefresh] = useState(false)
  const [StageListBarSate, setStageListBarSate] = useState(false)
  const [stageDateState, setStageDateState] = useState(false)
  const [waitingState, setWaitingState] = useState(false)
  const [AddBetSate, setAddBetSate] = useState(false)
  const [hour, sethours] = useState(0)
  const [minut, setminute] = useState(0)
  const [second, setseconds] = useState(0)
  const [winnersListPage, setWinnersListPage] = useState(1)
  const [allBetsPage, setAllBetsPage] = useState(1)
  const [ClaimLoadding, setClaimLoadding] = useState(-1)
  const [multiplier, setMultiplier] = useState('1')
  const [tag, setTag] = useState('Number')
  const [currentStage, setCurrentStage] = useState('')
  const [currentStageDate, setCurrentStageDate] = useState('')
  const [stopBetTime, setStopBetTime] = useState('')
  const [balls, setBalls] = useState([] as any )
  const [ticket, setTicket] = useState([] as any)
  const [stageList, setStageList] = useState([] as any)
  const [stageListAll, setStageListAll] = useState([] as any)
  const [stageDate, setStageDate] = useState([] as any)
  const [currentBall, setCurrentBall] = useState([] as any)
  const [numberBall, setNumberBall] = useState(NumberBall)
  const [stageData, setStageData] = useState([] as any)
  const [winnersList, setWinnersList] = useState([] as any)
  const [winnersListAll, setWinnersListAll] = useState([] as any)
  const [allBets, setAllBets] = useState([] as any)
  const [allBetsAll, setAllBetsAll] = useState([] as any)
  const [times, settimes] = useState([] as any)
  const [currentStageInfo, setCurrentStageInfo] = useState({} as any)

  const CalculateBonus = (item: any) => {
    if (currentStageInfo.overloadmultiples === '0') {
      if (item.winningStatus === 3) {
        return item.multiplier*5
      }
      if (item.winningStatus === 4) {
        return item.multiplier*100
      }
      if (item.winningStatus === 5) {
        return item.multiplier*5000
      }
    } else {
      const wei = chainId === 137||chainId === 80002? 1000000: 1000000000000000000
      const over = new BigNumber(currentStageInfo.overloadmultiples).div(wei).toNumber()
      if (item.winningStatus === 3) {
        const val = item.multiplier*5/over
        return new BigNumber(val).times(0.95).toNumber().toFixed(2)
      }
      if (item.winningStatus === 4) {
        const val = item.multiplier*100/over
        return new BigNumber(val).times(0.95).toNumber().toFixed(2)
      }
      if (item.winningStatus === 5) {
        const val = item.multiplier*5000/over
        return new BigNumber(val).times(0.95).toNumber().toFixed(2)
      }
    }
  }

  useEffect(() => {
    if (balls.length) {
      const data = numberBall.map((item: any) => {
        const index = balls.findIndex((ele: any) => ele === item.ball)
        if (index>= 0) {
          item.state = true
        }
        return item
      })
      setNumberBall(data)
    }
  },[balls])

  useEffect(() => {
    getContractInfo()
  },[chainId])

  useEffect(() => {
    if (stageListAll.length) {
      updateCountdown()
    } else {
      if (times.length) {
        closetime()
      }
    }
  },[stageListAll])

  useEffect(() => {
    if (currentStage) {
      getCurrentStageData()
      const info = stageListAll.filter((item: any) => {
        return item.stage === currentStage
      })
      setCurrentStageInfo(info[0])
    } else {
      setCurrentStageInfo({})
    }
  },[currentStage,refresh])

  useEffect(() => {
    if (currentStageDate) {
      const filetrDate = stageListAll.filter((item: any) => {
        return item.datetime.substring(0,10) === currentStageDate
      })
      const arr = [] as any
      filetrDate.map((item: any) => {
        arr.push(item.stage)
      })
      const sortarr = arr.sort((a: any,b: any)=> {return b*1-a*1})
      setStageList(sortarr)
      setCurrentStage(sortarr[0])
    } else {
      setCurrentStage('')
    }
  },[currentStageDate])

  useEffect(() => {
    setWinnersList(winnersListAll.slice((winnersListPage-1)*3, winnersListPage*3))
  },[winnersListPage])
  
  useEffect(() => {
    setAllBets(allBetsAll.slice((allBetsPage-1)*5, allBetsPage*5))
  },[allBetsPage])

  const BetAmount = useMemo(() => {
    let approvevalue = 0
    ticket.map(() => {
      approvevalue = approvevalue + 2 * Number(multiplier)
    })
    return approvevalue
  }, [ticket, multiplier])

  const updateCountdown = () => {
    const CountdownHours = stageListAll[stageListAll.length-1].datetime.substring(11, 13)
    const currentTime = stageListAll[stageListAll.length-1].datetime.substring(0, 10)
    const currentDay = stageListAll[stageListAll.length-1].datetime.substring(8, 10)
    const currentHours = new Date().getHours()
    if (currentHours >= CountdownHours*1+1) {
      const stopTime = CountdownHours*1+3===24? `${currentTime.substring(0,8)}${currentDay*1+1} 00:30:00`:`${currentTime} ${CountdownHours*1+3}:30:00`
      const targetTime = CountdownHours*1+3===24? `${currentTime.substring(0,8)}${currentDay*1+1} 01:00:00`:`${currentTime} ${CountdownHours*1+4}:00:00`
      setStopBetTime(stopTime)
      const distance = (new Date(targetTime).getTime()-new Date().getTime())
      if (distance > 0) {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        sethours(hours)
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        setminute(minutes)
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setseconds(seconds)
        const timeout = setTimeout(updateCountdown, 1000);
        const arr = []
        arr.push(timeout)
        settimes(arr)
      }
    } else {
      setWaitingState(true)
      const distance = (new Date(`${currentTime} ${CountdownHours*1+1}:00:00`).getTime()-new Date().getTime())
      if (distance > 0) {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        sethours(hours)
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        setminute(minutes)
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setseconds(seconds)
        const timeout = setTimeout(updateCountdown, 1000);
        const arr = []
        arr.push(timeout)
        settimes(arr)
      } else {
        setWaitingState(false)
      }
    }
  }

  const closetime = () => {
    setStopBetTime('')
    sethours(0)
    setminute(0)
    setseconds(0)
    for (const timer of times) {
      clearTimeout(timer)
    }
  }
  
  const getCurrentStageData = async () => {
    const data = await lotteryhttp.get(`/v0/betInfo_22Choose5s?drawNumbering=${currentStage}&chain=${chainName}`)
    const filterWinners = data.data.data.filter((item: any) => {
      return item.winningStatus >= 3
    })
    const sortwon = filterWinners.sort((a: any,b: any)=> {return a.betNumbering*1-b.betNumbering*1})
    setWinnersList(sortwon.slice(0, 3))
    setWinnersListAll(sortwon)
    const filterallBets = data.data.data.filter((item: any) => {
      return item.winningStatus < 3
    })
    const sortlost = filterallBets.sort((a: any,b: any)=> {return a.betNumbering*1-b.betNumbering*1})
    setAllBets(sortlost.slice(0, 5))
    setAllBetsAll(sortlost)
  }

  const getContractInfo = async () => {
    const stageListSata = await lotteryhttp.get(`/v0/cj_Settlement_225s?chain=${chainName}`)
    const date = [] as any
    stageListSata.data.data.map((item: any) => {
      const filterdata = date.filter((ele: any) => {
        return ele === item.datetime.substring(0,10)
      })
      if (!filterdata.length) {
        date.push(item.datetime.substring(0,10))
      }
    })
    const sortArr = stageListSata.data.data.sort((a: any,b: any)=> {return new Date(a.datetime).getTime()-new Date(b.datetime).getTime()})
    setStageListAll(sortArr)
    const sortdate = date.sort((a: any,b: any)=> {return new Date(b).getTime()-new Date(a).getTime()})
    setStageDate(sortdate)
    setCurrentStageDate(sortdate[0])
  }

  const SelectNumber = (number: number) => {
    if (balls.length >= 5) return
    const index = balls.findIndex((ele: any) => ele === number)
    if (index< 0) {
      setBalls([...balls,number])
    }
  }

  const refreshButton = () => {
    setBalls([])
    setNumberBall((Array.from({ length: 22 },(item: any,index: number) => BallItems(index))))
  }

  const ClaimButton = async (item: any, index: number) => {
    if (ClaimLoadding>=0) return
    setClaimLoadding(index)
    try {
      const deposited = await Control22_5?.drawreward(item.drawNumbering,item.betNumbering)
      Asset22_5?.once('drawreward', (from,value,betnumber,betstage)=>{
        // console.log(from,`${betnumber}`,`${betstage}`)
        if (
          from.toLowerCase() === account?.toLowerCase()
          &&`${betnumber}`===item.betNumbering
          &&`${betstage}`===item.drawNumbering
        ) {
          setClaimLoadding(-1)
          lotteryhttp.put(`/v0/betInfo_22Choose5s/${item.id}`,{rewardStatus: 1}).then(() => setrefresh(!refresh))
          Asset22_5?.removeAllListeners('drawreward')
        }
      })
    } catch (error) {
      setClaimLoadding(-1)
      Asset22_5?.removeAllListeners('drawreward')
    }
  }

  const deleteTicket = (item: any) => {
    const filterData = ticket.filter((ele: any) => {
      return `${ele}` !== `${item}`
    })
    setTicket(filterData)
  }

  const AddTicketButton = () => {
    if (ticket.length>=10 || balls.length!==5) return
    setTicket([...ticket,balls])
    refreshButton()
  }

  const AddBetButton = async () => {
    if (!ticket.length||!multiplier) return
    if (hour===0&&minut<=30||waitingState) return
    if (AddBetSate) return
    setAddBetSate(true)
    const Multipliers = [] as any
    let approvevalue = 0
    ticket.map(() => {
      Multipliers.push(multiplier)
      approvevalue = approvevalue + 2 * Number(multiplier)
    })
    
    let index = 0
    try {
      const approveval = chainId===137||chainId === 80002? approvevalue*1000000 : approvevalue*1000000000000000000
      const approvetx = await USDTContract?.approve(AssetAddress,approveval)
      USDTContract?.on('Approval', async (from,to,value,events)=>{
        console.log(from,to,`${value}`)
        if (Number(`${value}`)===approveval && from.toLowerCase() === account?.toLowerCase()&& to.toLowerCase() === AssetAddress?.toLowerCase()) {
          try {
            console.log(ticket,Multipliers)
            await Control22_5?.batchbet(ticket,Multipliers)
            USDTContract?.removeAllListeners()
          } catch (error) {
            setAddBetSate(false)
            USDTContract?.removeAllListeners()
            Control22_5?.removeAllListeners()
          }
        }
      })
      Asset22_5?.on('Betting', (from,value,hash,drawNumbering,betNumbering,transactionInfo)=>{
        console.log(from,index,ticket.length)
        if (from.toLowerCase() === account?.toLowerCase()&&index<ticket.length) {
          console.log( `${drawNumbering}`,`${betNumbering}`)
          const list = {
            userAddress: account,
            drawNumbering: `${drawNumbering}`,
            betNumbering: `${betNumbering}`,
            betNumbers: `${ticket[index]}`,
            multiplier: multiplier,
            transactionHash: transactionInfo.transactionHash,
            zcaddress: AssetAddress,
            chain: chainName
          }
          lotteryhttp.post('/v0/betInfo_22Choose5s', list)
          setTicket([])
          setMultiplier('1')
          setAddBetSate(false)
          toastify.success('Bet Successfully')
          index= index+1
          Control22_5?.removeAllListeners()
        }
      })
    } catch (error) {
      setAddBetSate(false)
      USDTContract?.removeAllListeners()
      Control22_5?.removeAllListeners()
    }
    // const approveval = chainId===137||chainId === 80002? approvevalue*1000000 : approvevalue*1000000000000000000
    // writeContract({
    //   address: '0x0Af805b236C8215ab67EFBaF249C74D75b916Cf1',
    //   abi,
    //   functionName: 'approve',
    //   args: [AssetAddress,BigInt(approveval)]
    // })
  }

  const pageButton = (dataname: string, type: string) => {
    if (dataname === 'WinnersList') {
      if (type === 'next') {
        if (winnersListPage>=Math.ceil(winnersListAll.length/3)) return
        setWinnersListPage(winnersListPage+1)
      }
      if (type === 'prev') {
        if (winnersListPage<=1) return
        setWinnersListPage(winnersListPage-1)
      }
    }
    if (dataname === 'AllBets') {
      if (type === 'next') {
        if (allBetsPage>=Math.ceil(allBetsAll.length/5)) return
        setAllBetsPage(allBetsPage+1)
      }
      if (type === 'prev') {
        if (allBetsPage<=1) return
        setAllBetsPage(allBetsPage-1)
      }
    }
  }

  const MultiplierChange = useCallback((ele: any) => {
    let val = ele.currentTarget.value
    if (!/^\d*$/.test(val)) {
      val = val.replace(/\D/,'')
    }
    if (val*1>0&& val*1<=20) {
      setMultiplier(val)
    }
    if (val==='') {
      setMultiplier('')
    }
  }, [])

  return (
    <Pool5_22Box className="main">
      {tag === 'Number' ? (
        <PoolInfo>
          <div className="poolName flex flex-v-center">
            <img src={Icon5_22} alt="" />
            5/22 Lottery
          </div>
          <div className="time">Stop Betting Time <span>{stopBetTime}</span></div>
          <div className="time">
            { waitingState?'Waiting To Start':'Next Draw Starts In '}
            <span>{hour}h:{minut}m:{second}s</span>
            {!hour&&!minut&&!second ? (
              <span className="Clearing">Clearing</span>
            ):''}
          </div>
          <div className="Prize flex flex-center">Recent Winning Prize <span>$5000</span></div>
        </PoolInfo>
      ):''}
      <TagBar className="flex">
        <div className={`Number text-center ${tag === 'Number' ? 'current':''}`} onClick={() => setTag('Number')}>Number</div>
        <div className={`Results text-center ${tag === 'Results' ? 'current':''}`} onClick={() => setTag('Results')}>Results</div>
      </TagBar>
      {tag === 'Number' ? (
        <NumberBox className="flex flex-h-between">
          <div>
            <AddTicket className="borderbox">
              {/* <div className="flex flex-h-between">
                <div className="color-4C83DF">Completed 1/1 Ticket(s)</div>
                <div className="flex flex-center color-0F69FF"><img src={pickIcon} alt="" />&nbsp;Quick Pick <span>Clear All</span></div>
              </div> */}
              <div className="ball flex wrap">
                {numberBall.map((item: any,index: number) => (
                  <div key={index} className={`text-center cursor ${item.state? 'selected':''}`} onClick={() => SelectNumber(item.ball)}>{item.ball}</div>
                ))}
              </div>
              <div className="flex flex-center">
                <div onClick={refreshButton} className="refreshButton cursor flex flex-center">
                  <img src={refreshIcon} alt="" />
                </div>
                <div className="selectedBalls flex flex-center">
                  <ItemBall ball={balls}></ItemBall>
                </div>
                <div className="color-0F69FF cursor" onClick={AddTicketButton}>Add 1 Ticket</div>
              </div>
            </AddTicket>
            <OrderList>
              <div className="color-0F69FF">Tickets</div>
              <div className="flex flex-j-end">
                <div className="clearAll cursor flex flex-v-center" onClick={() => setTicket([])}>
                  <img src={clearAllIcon} alt="" />&nbsp;Clear All
                </div>
              </div>
              <div className="list">
                {ticket.length? (
                  ticket.map((item: any, index: number) => (
                    <div key={index} className="listItem flex flex-v-center flex-around">
                      <div>#{index+1}</div>
                      <ItemBall ball={item}></ItemBall>
                      <img src={closeIcon} className="cursor" alt="" onClick={() => deleteTicket(item)} />
                    </div>
                  ))
                ):(
                  <div className="text-center color-0F69FF">No tickets added</div>
                )}
              </div>
            </OrderList>
          </div>
          <div>
            <AddBet className="borderbox">
              <div className="title color-0F69FF">Multiplier <span>(Up to 20x)</span></div>
              <div className="flex">
                <div className="input flex-center">
                  <input type="text" onChange={MultiplierChange} value={multiplier} />
                </div>
              </div>
              <div className="title color-0F69FF">Bet Amount</div>
              <div className="flex">
                <div className="input flex-center">$ 2.00</div>
              </div>
              <div className="USDTResults flex flex-h-between">
                <div className="color-0F69FF">Total Bet Amount</div>
                <div>{BetAmount} USDT</div>
              </div>
              <div className="flex flex-h-between">
                {/* <div className={`Button flex flex-center ${hour===0&&minut<=30||waitingState? 'unavailable not-allowed':'available cursor'}`} onClick={ApprovalButton}>
                  Approval
                  {ApprovalSate? (
                    <img src={loadding} alt="" />
                  ):''}
                </div> */}
                <div className={`Button flex flex-center ${hour===0&&minut<=30||waitingState? 'unavailable not-allowed':'available cursor'}`} onClick={AddBetButton}>
                  Add Bet 
                  {AddBetSate? (
                    <img src={loadding} alt="" />
                  ):''}
                </div>
              </div>
            </AddBet>         
            <PreviousWinning>
              <div className="title flex flex-v-center">
                <img src={awardIcon} alt="" />
                <div>Previous Winning Numbers</div>
              </div>
              <div className="item">
                <div className="flex flex-around flex-v-center">
                  <img src={icon_1} alt="" />
                  <div>
                    <div className="text-right">
                      {currentStageInfo?.datetime?.substring(0,10)||''}&nbsp;
                      {currentStageInfo?.datetime?.substring(11,19)||''}
                    </div>
                    <div className="ball flex flex-j-start">
                      <ItemBall ball={currentStageInfo?.betresults?.split(",")|| []}></ItemBall>
                    </div>
                    <div className="Mistake">Pool Number <span>(Correct/Mistake): 5 / 5</span></div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="flex flex-around flex-v-center">
                  <img src={icon_2} alt="" />
                  <div>
                    <div className="text-right">
                      {stageListAll[stageListAll.length-2]?.datetime?.substring(0,10)||''}&nbsp;
                      {stageListAll[stageListAll.length-2]?.datetime?.substring(11,19)||''}
                    </div>
                    <div className="ball flex flex-j-start">
                      <ItemBall ball={stageListAll[stageListAll.length-2]?.betresults?.split(",")|| []}></ItemBall>
                    </div>
                    <div className="Mistake">Pool Number <span>(Correct/Mistake): 5 / 5</span></div>
                  </div>
                </div>
              </div>
            </PreviousWinning>
          </div>
        </NumberBox>
      ):''}
      {tag==='Results' ? (
        <ResultBox>
          <div className="selectEncoding flex flex-v-center relative">
            <div>Game ID</div>
            <div className="icon flex flex-center" onClick={() => setStageDateState(!stageDateState)}>
              <img className="cursor" src={icon_max} alt="" />
            </div>
            {stageDateState? (
              <StageDateBar className="stageDateBar borderbox">
                {stageDate.length? (
                  stageDate.map((item: any, index: number) => (
                    <div key={index} className="item text-center cursor" onClick={() => {setCurrentStageDate(item);setStageDateState(false)}}>{item}</div>
                  ))
                ):''}
              </StageDateBar>    
            ):''}
            <div className="Encoding flex flex-h-between relative">
              <div className="flex flex-center">{currentStage}</div>
              <div className="SwitchEncoding flex flex-center cursor" onClick={() => setStageListBarSate(!StageListBarSate)}>
                <img src={icon} alt="" />
              </div>
              {StageListBarSate&&!stageDateState? (
                <StageListBar>
                  {stageList.length? (
                    stageList.map((item: any, index: number) => (
                      <div key={index} className="item text-center cursor" onClick={() => {setCurrentStage(item);setStageListBarSate(false)}}>{item}</div>
                    ))
                  ):''}
                </StageListBar>
              ):''}
            </div>
          </div>
          <div className="winningInfo flex">
            <div className="flex-1">
              <div className="Drawtime">
                Draw time:&nbsp;
                {currentStageInfo?.datetime?.substring(0,10)||''}&nbsp;
                {currentStageInfo?.datetime?.substring(11,19)||''}
                </div>
              <div className="LUCKY flex flex-center"><ItemBall ball={currentStageInfo?.betresults?.split(",")|| []}></ItemBall></div>
            </div>
            <div className="total flex-1">
              <div className="flex flex-center">
                <img src={icon1} alt="" />
                Total Tickets Sold This Round: <span>{currentStageInfo?.betquantity|| 0}</span>
              </div>
              <div className="flex flex-center">
                <img src={winningIcon} alt="" />
                Total Winning Tickets In This Round: <span>{currentStageInfo?.winers|| 0}</span>
              </div>
            </div>
          </div>
          <div className="barTitle flex flex-center">
            <div className="border"></div>
            <div>Winners List</div>
            <div className="border"></div>
          </div>
          <WinnersListBox className="flex flex-h-between relative">
            {winnersList.length? (
              winnersList.map((item: any, index: number) => (
                <div key={index} className="item">
                  <div className="bar">
                    <div className="flex flex-h-between">
                      <div>Ticket Number</div>
                      <div>Date</div>
                    </div>
                    <div className="flex flex-h-between white">
                      <div>{item.betNumbering}</div>
                      <div>
                        {item.createdAt?.substring(0,10)||''}&nbsp;
                        {item.createdAt?.substring(11,19)||''}
                      </div>
                    </div>
                  </div>
                  <div className="bar">
                    <div className="flex flex-h-between">
                      <div>Numbers</div>
                      <div>Multiplier</div>
                    </div>
                    <div className="flex flex-h-between white">
                      <div><ItemBall ball={item.betNumbers.split(",")} list={1}></ItemBall></div>
                      <div>{item.multiplier}</div>
                    </div>
                  </div>
                  <div className="bar Label">
                    <div className="flex flex-h-between">
                      <div>Owner</div>
                      <div>Reward</div>
                    </div>
                    <div className="flex flex-h-between white">
                      <div>{formatting(item.userAddress)}</div>
                      <div>{CalculateBonus(item)}</div>
                    </div>
                  </div>
                  {item.userAddress === account? (
                    item.rewardStatus? (
                      <div className="Claims flex flex-center">Claim</div>
                    ) : (
                      <div className="Claim flex flex-center cursor" onClick={() => ClaimButton(item,index)}>
                        Claim
                        {ClaimLoadding === index? (
                          <img src={loadding} alt="" />
                        ):''}
                      </div>
                    )
                  ) : ''}
                </div>
              ))
            ):(
              <div className="NotData white">Not Data</div>
            )}
          </WinnersListBox>
          <PagingBar className="flex flex-v-center flex-j-end">
            <img src={icon_prev} alt="" onClick={() => pageButton('WinnersList','prev')} />
            <div>{winnersListPage}/{Math.ceil(winnersListAll.length/3)}</div>
            <img src={icon_next} alt="" onClick={() => pageButton('WinnersList','next')} />
          </PagingBar>
          <div className="barTitle flex flex-center">
            <div className="border"></div>
            <div>All Bets</div>
            <div className="border"></div>
          </div>
          <AllBetsBox className="flex flex-h-between wrap relative">
            {allBets.length ? (
              allBets.map((item: any, index: number) => (
                <div key={index} className="item">
                  <div className="flex flex-h-between flex-v-center">
                    <div className="white bigSize">#{item.betNumbering}</div>
                    <div className="Data">
                      <div>Date</div>
                      <div className="white">
                        {item.createdAt?.substring(0,10)||''}&nbsp;
                        {item.createdAt?.substring(11,19)||''}
                      </div>
                    </div>
                  </div>
                  <div className="ball flex flex-justify-content"><ItemWhiteBall ball={item.betNumbers.split(",")}></ItemWhiteBall></div>
                  <div className="Hash Abbreviation white cursor">{item.transactionHash}</div>
                </div>
              ))
            ):(
              <div className="NotData white">Not Data</div>
            )}
          </AllBetsBox>
          <PagingBar className="flex flex-v-center flex-j-end">
            <img src={icon_prev} alt="" onClick={() => pageButton('AllBets','prev')} />
            <div>{allBetsPage}/{Math.ceil(allBetsAll.length/5)}</div>
            <img src={icon_next} alt="" onClick={() => pageButton('AllBets','next')} />
          </PagingBar>
        </ResultBox>
      ):''}
    </Pool5_22Box>
  )
}