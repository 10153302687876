import { useContext, useEffect, useState } from "react";
import { useWeb3React } from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts'
import { isMobile } from 'react-device-detect'
import { StoreContext } from '../components/Store';
import { NetworkContextName } from '../utils';
import { injected } from '../connectors';
import Asset22_5 from '../connectors/Abi/22Choose5Asset.json'
import Control22_5 from '../connectors/Abi/22Choose5Control.json'
import Asset3D from '../connectors/Abi/3DAsset.json'
import Control3D from '../connectors/Abi/3DControl.json'
import USDTAbi from '../connectors/Abi/usdtAbi.json'
import {
  BSCUSDT,
  PolygonUSDT,
  polygontest22_5Asset,
  polygontest22_5Control,
  polygontest3DAsset,
  polygontest3DControl,
  polygon22_5Asset,
  polygon22_5Control,
  polygon3DAsset,
  polygon3DControl,
  polygontest5DAsset,
  polygontest5DControl,
  polygon5DAsset,
  polygon5DControl
} from "../constants";

export declare enum ChainId {
  ETHEREUM = 1,
  POLYGON = 137,
  BSC = 56,
  ONE = 42161,
  LOCALNODE = 1337,
  NOVA = 42170,
  SEPOLIA = 11155111,
  AMOY = 80002
}

export function useStore() {
  return useContext(StoreContext)
}

export function useEagerConnect() {
  const { activate, active } = useWeb3React()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && (window as any).ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React()

  useEffect((): any => {
    const { ethereum } = window as any
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event")
        activate(injected)
      }
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId)
        activate(injected)
      }
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts)
        if (accounts.length > 0) {
          activate(injected)
        }
      }
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId)
        activate(injected)
      }

      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('chainChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('chainChanged', handleNetworkChanged)
        }
      }
    }
  }, [active, error, suppress, activate])
}

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3React<Web3Provider>()
  const contextNetwork = useWeb3React<Web3Provider>(NetworkContextName)
  
  return context.active ? context : contextNetwork
}

export function useUSDTContract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract(BSCUSDT, USDTAbi, library.getSigner())
  } else if (chainId === 137) {
    return new Contract(PolygonUSDT, USDTAbi, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract('0x1861f264374F0420b8DDCB5e91BD8Bf0762be38f', USDTAbi, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('0xF524619b87045dF6833bF6101CF97B9D72Ea6ACa', USDTAbi, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract('0x0Af805b236C8215ab67EFBaF249C74D75b916Cf1', USDTAbi, library.getSigner())
  }
}

export function useAsset22_5Contract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract('', Asset22_5, library.getSigner())
  } else if (chainId === 137) {
    return new Contract(polygon22_5Asset, Asset22_5, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract('', Asset22_5, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('', Asset22_5, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract(polygontest22_5Asset, Asset22_5, library.getSigner())
  }
}

export function useControl22_5Contract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract('', Control22_5, library.getSigner())
  } else if (chainId === 137) {
    return new Contract(polygon22_5Control, Control22_5, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract('', Control22_5, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('', Control22_5, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract(polygontest22_5Control, Control22_5, library.getSigner())
  }
}

export function useAsset3DContract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract('', Asset3D, library.getSigner())
  } else if (chainId === 137) {
    return new Contract(polygon3DControl, Asset3D, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract('', Asset3D, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('', Asset3D, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract(polygontest3DAsset, Asset3D, library.getSigner())
  }
}

export function useControl3DContract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract('', Control3D, library.getSigner())
  } else if (chainId === 137) {
    return new Contract(polygon3DAsset, Control3D, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract('', Control3D, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('', Control3D, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract(polygontest3DControl, Control3D, library.getSigner())
  }
}

export function useAsset5DContract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract('', Asset22_5, library.getSigner())
  } else if (chainId === 137) {
    return new Contract('', Asset22_5, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract(polygon5DAsset, Asset22_5, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('', Asset22_5, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract(polygontest5DAsset, Asset22_5, library.getSigner())
  }
}

export function useControl5DContract() {
  const { library, chainId } = useActiveWeb3React()
  if (!library) return null
  if (chainId === 56) {
    return new Contract('', Control22_5, library.getSigner())
  } else if (chainId === 137) {
    return new Contract('', Control22_5, library.getSigner())
  } else if (chainId === 1337) {
    return new Contract(polygon5DControl, Control22_5, library.getSigner())
  } else if (chainId === 11155111) {
    return new Contract('', Control22_5, library.getSigner())
  } else if (chainId === 80002) {
    return new Contract(polygontest5DControl, Control22_5, library.getSigner())
  }
}