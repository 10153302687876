import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../connectors'
import { ButtonProps } from 'antd/lib/button/button'
import { useStore } from '../hooks'
import metamaskLogo from '../assets/metamask_logo.png'
import okxLogo from '../assets/Okx_logo.jpg'


const ConnectWalletBox = styled.div`
  .ConnectButton {
    background: linear-gradient(90deg, #9741D3 0%, #0F69FF 100%);
    box-shadow: 6px 6px 20px 0px rgba(8,8,29,0.6), -4px -4px 10px 0px rgba(70,84,132,0.5);
    border-radius: 30px;
    color: #fff;
    border: none;
  }
`

const SwitchWallet = styled.div`
  width: 125px;
  background-clip: padding-box;
  background: #2A3A75;
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 35px;
  left: 0px;
  div {
    display: flex;
    height: 40px;
    padding: 8px 16px 0 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &:hover {
      background-color: #0F69FF;
    }
  } 
`

export const ConnectWallet: React.FC<ButtonProps> = ({ ...props }) => {
  const { activate } = useWeb3React<Web3Provider>()
  const { setActivatingConnector } = useStore()
  const { setWelletType } = useStore()
  const [showWelletType, setShowWelletType] = useState(false)
  const okxwallet = (window as any).okxwallet
  const { ethereum } = window as any
  // const okxwallet = 'undefined'
  
  useEffect(() => {
    // closeOkxConnect()
    // console.log(okxwallet)
    // okxConnect()
  },[])

  // okxwallet.on('disconnect',()=> console.log('disconnect'))

  const handleConnect = () => {
    activate(injected)
    setActivatingConnector(injected)
    setWelletType('MetaMask')
  }

  const okxConnect = async () => {
    // const res = await okxwallet.request({ method: 'eth_requestAccounts' })
    // setWelletType('Okx')
    const res = await okxwallet.request({ method: 'wallet_disconnect' })
  }

  return (
    <ConnectWalletBox className="flex flex-justify-content">
      {/* {okxwallet === 'undefined' ? (
        <Button {...props} onClick={handleConnect} className="relative ConnectButton">
          Connect Wallet
        </Button>
      ) : (
        <Button className="relative ConnectButton" onClick={() => setShowWelletType(!showWelletType)}>
          Connect Wallet
          {showWelletType ? (
            <SwitchWallet>
              <div onClick={okxConnect}>
                <img src={okxLogo} alt="" />
                OKX
              </div>
              <div onClick={handleConnect}>
                <img src={metamaskLogo} alt="" />
                MetaMask
              </div>
            </SwitchWallet>
          ):''}
        </Button>
      )} */}
      <Button {...props} onClick={handleConnect} className="relative ConnectButton">
        Connect Wallet
      </Button>
    </ConnectWalletBox>
  )
}
