
import { Route, Switch } from 'react-router-dom';
import './App.css';
import './Global.css'
import styled from 'styled-components';
import { Navigation } from './components/Navigation';
import { Web3Manager } from './components/Web3Manager';
import { Pool5_22 } from './components/Pool5_22';
import { Pool3 } from './components/Pool3';
import { Pool5 } from './components/Pool5';
import { MyPage } from './components/MyPage';

const ContentArea = styled.div`
  flex: 4;
`

function App() {
  return (
    <div className="App flex">
      <Navigation />
      <Web3Manager>
        <ContentArea>
          <Switch>
            <Route exact path="/" component={Pool3} />
            <Route exact path="/5DLottery" component={Pool5} />
            <Route exact path="/5_22Lottery" component={Pool5_22} />
            <Route exact path="/dashboard" component={MyPage} />
          </Switch>
        </ContentArea>
      </Web3Manager>
    </div>
  );
}

export default App;
